import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { User } from 'src/app/core/models/user';

import { ApiResponse } from 'src/app/core/interfaces/api-response';

import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

import { SharedDataService } from '../shared-data/shared-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private sessionStorageServ: SessionStorageService,
    private sharedDataServ: SharedDataService,
    private http: HttpClient
  ) {}

  // get user from local storage
  getStoredUser(): User {
    return this.sessionStorageServ.getItem('user');
  }

  // in both local storage + subject
  updateStoredUser(updatedUser: User): void {
    this.sessionStorageServ.setItem('user', updatedUser);
    this.sharedDataServ.updateConnectedUserSubj(updatedUser);
  }

  getUser(): Promise<any> {
    return this.http
      .get(environment.apiURL + 'users/me')
      .pipe(map((response: ApiResponse) => response.data))
      .toPromise();
  }

  generateApiAndSecretKey(): Observable<any> {
    return this.http
      .put(environment.apiURL + 'users/api-secret', {})
      .pipe(map((response: ApiResponse) => response.data));
  }

  getUserRole(): string {
    const user = this.sessionStorageServ.getItem('user');
    return user.role;
  }

  checkIfUserInfoComplete(): boolean {
    const user = this.sessionStorageServ.getItem('user');
    return user.completed;
  }
}
