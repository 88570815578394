<div class="fy-alert-dialog">
  <div class="fy-alert-dialog__alert-icon">
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 511.999 511.999"
      style="enable-background: new 0 0 511.999 511.999"
      xml:space="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M506.175,365.576c-4.095-10.258-15.729-15.255-25.987-11.16c-10.258,4.095-15.255,15.729-11.16,25.987
				c5.206,13.043,3.499,27.935-4.567,39.839c-8.07,11.91-20.991,18.741-35.449,18.741H83.068c-15.771,0-29.776-8.229-37.465-22.015
				c-7.701-13.809-7.339-30.082,0.968-43.533L219.543,93.382c7.876-12.752,21.519-20.365,36.497-20.365
				c14.978,0,28.62,7.613,36.497,20.365l127.927,207.124c5.804,9.397,18.128,12.312,27.523,6.505
				c9.396-5.803,12.31-18.126,6.505-27.523L326.564,72.364c-15.216-24.636-41.58-39.344-70.525-39.344s-55.309,14.709-70.525,39.344
				L12.542,352.418c-16.036,25.966-16.734,57.38-1.869,84.033c14.853,26.63,41.916,42.528,72.395,42.528h345.944
				c27.938,0,52.927-13.233,68.56-36.303C513.088,419.778,516.303,390.955,506.175,365.576z"
            />
            <path
              d="M256.049,399.987c11.045,0,19.998-8.953,19.998-19.998c0-11.045-8.953-19.998-19.998-19.998h-0.01
				c-11.045,0-19.993,8.953-19.993,19.998C236.046,391.034,245.004,399.987,256.049,399.987z"
            />
            <path
              d="M276.038,309.995V163.008c0-11.045-8.953-19.998-19.998-19.998c-11.045,0-19.998,8.953-19.998,19.998v146.987
				c0,11.045,8.953,19.998,19.998,19.998C267.085,329.993,276.038,321.04,276.038,309.995z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
  <div class="fy-alert-dialog__text-box">
    <p class="no-margin">{{ data.alertMessage }}</p>
  </div>
  <div class="fy-alert-dialog__controls">
    <button mat-raised-button class="fy-button fy-alert-dialog__alert-btn" (click)="redirectTo()">
      {{ data.buttonText }}
    </button>
  </div>
</div>
