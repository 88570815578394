<div class="fy-banner">
  <span class="fy-banner__text" translate>
    <p class="title">
      Nous sommes ravis de vous présenter Back Office V2, la prochaine évolution pour gérer vos opérations de manière transparente et efficace.
    </p>
    <p>
      La V2 n'est pas seulement une mise à niveau, c'est une transformation. Passez à la V2 dès aujourd'hui et découvrez une expérience de back-office plus puissante, plus efficace et plus conviviale que jamais. Prêt à améliorer vos opérations ?
    </p>
  </span>
  <button mat-button class="fy-banner__button" translate (click)="openV2()">Essayez maintenant !</button>
</div>

<div class="fy-signin fy-public-page">
  <div class="fy-public-page__content">
    <div class="fy-signin__header">
      <div class="fy-signin__header__logo">
        <img src="/assets/icons/lyzi.svg" alt="lyzi logo" />
      </div>
      <p class="fy-signin__header__title" translate>public.signin.title</p>
      <p class="fy-signin__header__subtitle" translate>public.signin.subtitle</p>
    </div>
    <div class="fy-form">
      <form class="fy-form__content" role="form">
        <!-- Email field -->
        <mat-form-field>
          <mat-label translate>forms.email.label</mat-label>
          <input matInput [formControl]="email" type="email" autocomplete="email" />
          <mat-error *ngIf="email.errors?.required" translate>forms.email.errors.required</mat-error>
          <mat-error *ngIf="email.errors?.email" translate>forms.email.errors.email</mat-error>
        </mat-form-field>

        <!-- Password field -->
        <mat-form-field>
          <mat-label translate>forms.password.label</mat-label>
          <input matInput [formControl]="password" type="password" autocomplete="current-password" />
          <mat-error *ngIf="password.errors?.required" translate>forms.password.errors.required</mat-error>
        </mat-form-field>

        <div class="fy-signin__forget-password">
          <a routerLink="/reset" translate>public.signin.forgot-password</a>
        </div>

        <div class="fy-signin__controls-box">
          <button
            mat-raised-button
            class="fy-button"
            [disabled]="signInForm.invalid || isLoading"
            (click)="onSignIn()"
            type="submit"
          >
            <mat-spinner *ngIf="isLoading" [strokeWidth]="2" [diameter]="30"></mat-spinner>
            <span *ngIf="!isLoading" translate>public.signin.submit</span>
          </button>

          <div class="fy-signin__controls-box__signup-link">
            <span class="qst-text" translate>public.signin.no-account</span>&nbsp;
            <a class="terms-txt" routerLink="/signup" translate>public.signin.signup</a>
          </div>
        </div>
      </form>
    </div>

    <div class="fy-signin__footer">
      <span translate>public.signin.version</span>&nbsp;
      <span>{{ appVersion }}</span>
    </div>
  </div>
</div>
