/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Services
import { AuthService } from '@core/services/auth/auth.service';

// Interfaces
import { ApiResponse } from '@core/interfaces/api-response';

// Types
import { ImpersonationQueryParams } from '@shared/types/impersonation';

// Others
import { environment } from '@environments/environment';

/* -------------------------------------------------------------------------- */
/*                                  Service                                   */
/* -------------------------------------------------------------------------- */

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  readonly sessionParamName: string = 'q';

  /**
   *
   * Send pin code
   */
  sendPinCode(): Observable<{ pinCodeIsSent: boolean }> {
    return this.http
      .post<ApiResponse>(environment.apiURL + `super-admins/impersonation/send-pin-code`, {})
      .pipe(map((response: ApiResponse) => response.data));
  }

  /**
   *
   * Check pin code
   * @param pinCode - pin code entered by the user
   */
  checkPinCode(pinCode: number): Observable<{ isAuthenticated: boolean }> {
    return this.http
      .post<ApiResponse>(environment.apiURL + `super-admins/impersonation/check-pin-code`, { pinCode })
      .pipe(map((response: ApiResponse) => response.data));
  }

  login(queryParams: ImpersonationQueryParams): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(
        environment.apiURL + `super-admins/impersonation/login`,
        {
          pinCode: queryParams['pin-code'],
          managerId: queryParams.managerId
        },
        {
          headers: {
            Authorization: `Bearer ${queryParams.token}`
          }
        }
      )
      .pipe(
        map((res: ApiResponse) => {
          this.authService.storeUser(res.data);
          return res;
        })
      );
  }
}
