const errors = {
  fr: {
    802: 'Identifiants incorrectes',
    806: 'Le code OTP est incorrect',
    810: 'Le code OTP est expiré.',
    811: 'Le code OTP est invalide. Trop de tentatives. Attendez 5 minutes et réessayez.',
    1000: `Nous n'avons pas pu trouver un compte associé à cet email`,
    1001: 'Cet email est déjà utilisé',
    1004: `L'utilisateur n'a pas été vérifié`,
    1005: `L'utilisateur a un rôle non valide`,
    2001: 'Ce nom de société est déjà utilisé',
    2004: 'IBAN invalide !',
    2005: 'IBAN déjà utilisé par un autre compte',
    2007: 'IBAN ne peut pas être modifié. Veuillez contacter le support',
    4003: `Limite d'offres atteinte`,
    5002: 'Ce commerce est déjà dans votre programme',
    6001: 'Point de vente déjà existant',
    9000: 'Forfait introuvable',
    9001: 'Forfait existe déjà',
    9002: `La carte et l'intervalle sont obligatoires`,
    9003: 'Vous êtes déjà abonné à ce forfait',
    9005: "Pack business en cours d'utilisation",
    10000: 'Participation introuvable',
    10001: 'Le client existe déjà',
    10002: `Le client n'a pas assez de points`,
    10003: 'Limite de points atteinte',
    10004: 'Limite de clients atteinte',
    10005: 'Le nombre de points doit être positif',
    14001: 'Invitation déjà envoyée',
    16000: 'Caisse introuvable',
    16001: 'Caisse inactive',
    17000: 'Bouton introuvable',
    17001: 'Le bouton existe déjà',
    24003: 'Le NFT a expiré.',
    24004: 'Niveau maximal du NFT atteint.',
    24005: 'Le niveau du NFT est trop bas.',
    27000: 'Code PIN incorrect.',
    27001: 'Le code PIN a expiré. Veuillez vérifier votre e-mail. Un nouveau code PIN a été envoyé avec succès.',
    27002: 'Code PIN incorrect. Veuillez vérifier votre e-mail. Un nouveau code PIN a été envoyé avec succès.',
    27005: 'Code PIN invalide. Trop de tentatives. Attendez 5 minutes et réessayez'
  }
};

export default errors;
