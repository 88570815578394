import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { environment } from '@environments/environment';
import { User } from '@core/models/user';
import { tap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  appVersion = environment.appVersion;
  isLoading = false;
  user: User;
  otpForm: FormGroup

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    try {
      this.user = this.router.getCurrentNavigation()?.extras?.state?.user as User;
    } catch (error) {
      console.log(error, this.router.getCurrentNavigation()?.extras)
    }

  }

  ngOnInit(): void {
    if(!this.user) {
      this.router.navigate([''])
    }
    this.otpForm = new FormGroup({
      code: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  get code(): AbstractControl {
    return this.otpForm.get('code');
  }

  validate(): void {
    let code = this.code?.value?.toString();
    if(!code?.length) {
      return;
    }
    // Making sure it is not 6
    code = code.slice(0,6);
    this.isLoading = true;

    this.authService
    .validateUserOTP({userId: this.user._id, otp: "", emailOtp: code } )
    .pipe(
      tap(({ user }) => {
        switch (user.role) {
          case 'Association':
            this.router.navigate(['city']);
            break;
          case 'Manager':
          case 'SuperManager':
            this.router.navigate(['backoffice']);
            break;
          case 'SuperAdmin':
            this.router.navigate(['admin']);
            break;
        }
      }),
      finalize(() => (this.isLoading = false))
    )
    .subscribe();
  }

}
