import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@core/guards/auth/auth.guard';

import { SigninComponent } from '@public/signin/signin.component';
import { SignupComponent } from '@public/signup/signup.component';
import { CitySignupComponent } from '@public/city-signup/city-signup.component';
import { CgvComponent } from '@public/cgv/cgv.component';
import { ResetPasswordComponent } from '@public/reset-password/reset-password.component';
import { ResetSuccessComponent } from '@public/reset-success/reset-success.component';
import { NotFoundComponent } from '@public/not-found/not-found.component';
import { OtpComponent } from '@public/otp/otp.component';

const routes: Routes = [
  {
    path: 'city',
    loadChildren: () => import('src/app/city/city.module').then((m) => m.CityModule),
    canLoad: [AuthGuard],
    data: {
      allowedRoles: ['Association']
    }
  },
  {
    path: 'backoffice',
    loadChildren: () => import('src/app/backoffice/backoffice.module').then((m) => m.BackofficeModule),
    canLoad: [AuthGuard],
    data: {
      allowedRoles: ['Manager', 'SuperManager']
    }
  },
  {
    path: 'supermanager',
    loadChildren: () => import('src/app/super-manager/super-manager.module').then((m) => m.SuperManagerModule),
    canLoad: [AuthGuard],
    data: {
      allowedRoles: ['SuperManager']
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('src/app/admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AuthGuard],
    data: {
      allowedRoles: ['SuperAdmin']
    }
  },
  {
    path: 'buy-button',
    loadChildren: () => import('src/app/buy-button/buy-button.module').then((m) => m.BuyButtonModule)
  },
  {
    path: 'signin',
    canActivate: [AuthGuard],
    component: SigninComponent
  },
  {
    path: 'otp',
    component: OtpComponent
  },
  {
    path: 'signup',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: SignupComponent
      },
      {
        path: 'city',
        component: CitySignupComponent
      }
    ]
  },
  {
    path: 'reset',
    component: ResetPasswordComponent
  },
  {
    path: 'terms-conditions',
    component: CgvComponent
  },
  {
    path: 'reset-success',
    component: ResetSuccessComponent
  },
  {
    path: 'notfound',
    component: NotFoundComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/signin'
  },
  {
    path: '**',
    redirectTo: '/notfound'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 0]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
