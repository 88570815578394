import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConversionHelper } from '@buybutton/_helpers';
import {
  AcceptConversionParams,
  ConfirmConversionParams,
  IButton,
  IButtonAppFinalParams,
  IButtonAppParams,
  IButtonSession,
  IConversion,
  IGoodsCategory
} from '@buybutton/_interface';
import { LyziHttpClientService } from '@core/services/http-clients/lyzi-http-client.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {
  constructor(
    private lyziHttp: LyziHttpClientService,
    private sessionStorageService: SessionStorageService,
    private _location: Location,
    private _router: Router
  ) { }

  requestConversion(data: IButtonAppFinalParams): Observable<IConversion> {
    if (data?.goods?._id) {
      delete data.goods._id;
    }
    const returnTree = this._router.createUrlTree(['buy-button/status'], {
      queryParams: {
        error: false
      }
    });
    const returnUrl = location.origin + this._location.prepareExternalUrl(returnTree.toString());
    const cancelTree = this._router.createUrlTree(['buy-button/status'], {
      queryParams: {
        error: true
      }
    });
    const cancelUrl = location.origin + this._location.prepareExternalUrl(cancelTree.toString());

    return this.lyziHttp
      .post<any>(environment.apiURL + 'confirm_conversion/request', {
        amount: data.price,
        fromAsset: data.currency,
        toAsset: 'USDT',
        goods: data.goods,
        webhookUrl: data.callbackUrl,
        returnUrl: returnUrl.toString(),
        cancelUrl: cancelUrl.toString()
      })
      .pipe(map((resp) => resp.data));
  }

  requestConversionWithSession(data: IButtonAppFinalParams): Observable<IButtonSession> {
    return this.requestConversion(data).pipe(
      switchMap((conversion) => {
        let orderRef = data?.orderRef;

        if (!orderRef || orderRef == undefined || orderRef == null || orderRef == '') {
          orderRef = ConversionHelper.generateRandomString(6);
        }

        return this.lyziHttp.post<any>(environment.apiURL + 'buy-button/sessions', {
          buttonName: data.buttonName,
          conversionCode: conversion.code,
          orderRef: `${this.appParams.id}~${orderRef}`,
          price: data.price,
          button: data._id,
          callbackUrl: data.callbackUrl,
          returnUrl: data.returnUrl,
          cancelUrl: data.cancelUrl,
          currency: data.currency,
          goods: data.goods
        });
      }),
      map((session) => session.data),
      switchMap((session: IButtonSession) => {
        return this.getButtonSession(session._id);
      })
    );
  }

  getButtonSession(sessionId: String): Observable<IButtonSession> {
    return this.lyziHttp.get(environment.apiURL + 'buy-button/sessions/' + sessionId).pipe(map((r: any) => r.data));
  }

  removeButtonSession(sessionId: String): Observable<any> {
    return this.lyziHttp.delete(environment.apiURL + 'buy-button/sessions/' + sessionId);
  }

  acceptConversion(data: AcceptConversionParams): Observable<IConversion> {
    return this.lyziHttp
      .post<any>(environment.apiURL + 'confirm_conversion/accept/' + data.code, {
        returnUrl: data.returnUrl,
        cancelUrl: data.cancelUrl,
        paymentChannel: data.paymentChannel
      })
      .pipe(map((resp) => resp.data));
  }

  confirmConversion(data: ConfirmConversionParams): Observable<IConversion> {
    return this.lyziHttp
      .post<any>(environment.apiURL + 'confirm_conversion/confirm/' + data.code, {
        websiteUrl: data.websiteUrl,
        code: data.code
      })
      .pipe(map((resp) => resp.data));
  }

  getButton(id: string): Observable<IButton> {
    return this.lyziHttp.get(environment.apiURL + 'buy-buttons/' + id).pipe(map((resp: any) => resp.data));
  }

  getExchangeRates(fromAsset?: string, toAsset?: string): Observable<any> {
    return this.lyziHttp.get<any>(environment.apiURL + '/confirm_conversion/exchangeInfo', {
      params: {
        fromAsset,
        toAsset
      }
    });
  }

  getConversionStatus(code: string): Observable<IConversion> {
    return this.lyziHttp.get<any>(environment.apiURL + 'confirm_conversion/status/' + code).pipe(
      map((resp) => {
        return resp.data;
      })
    );
  }

  cancelConversion(code: string): Observable<IConversion> {
    return this.lyziHttp.post<any>(environment.apiURL + 'confirm_conversion/cancel/' + code, {}).pipe(
      map((resp) => {
        return resp.data;
      })
    );
  }

  storeCallbackToLog(url: string, data?: any): Observable<any> {
    return this.lyziHttp.post(environment.apiURL + 'confirm_conversion/callback/log', {
      url,
      data,
      origin: 'Button web',
    });
  }

  goodsCategory(): IGoodsCategory[] {
    return [
      { value: '0000', label: 'Electronics & Computers' },
      { value: '1000', label: 'Books, Music & Movies' },
      { value: '2000', label: 'Home, Garden & Tools' },
      { value: '3000', label: 'Clothes, Shoes & Bags' },
      { value: '4000', label: 'Toys, Kids & Baby' },
      { value: '5000', label: 'Automotive & Accessories' },
      { value: '6000', label: 'Game & Recharge' },
      { value: '7000', label: 'Entertainament & Collection' },
      { value: '8000', label: 'Jewelry' },
      { value: '9000', label: 'Domestic service' },
      { value: 'A000', label: 'Beauty care' },
      { value: 'B000', label: 'Pharmacy' },
      { value: 'C000', label: 'Sports & Outdoors' },
      { value: 'D000', label: 'Food, Grocery & Health products' },
      { value: 'E000', label: 'Pet supplies' },
      { value: 'F000', label: 'Industry & Science' },
      { value: 'Z000', label: 'Others' }
    ];
  }

  set appParams(params: IButtonAppParams) {
    this.sessionStorageService.setItem('appParams', params);
  }

  get appParams(): IButtonAppParams {
    return this.sessionStorageService.getItem('appParams');
  }

  set finalAppParams(params: IButtonAppFinalParams) {
    this.sessionStorageService.setItem('finalAppParams', params);
  }

  get finalAppParams(): IButtonAppFinalParams {
    return this.sessionStorageService.getItem('finalAppParams');
  }

  // set buyButtonData(params: FinalParams) {
  //   this.sessionStorageService.setItem('buyButtonData', params);
  // }

  // get buyButtonData(): FinalParams {
  //   return this.sessionStorageService.getItem('buyButtonData');
  // }

  set conversionSession(params: IButtonSession<IButton>) {
    this.sessionStorageService.setItem('conversionSession', params);
  }

  get conversionSession(): IButtonSession<IButton> {
    return this.sessionStorageService.getItem('conversionSession');
  }

  // set currentConversion(params: ConversionResponseData) {
  //   this.sessionStorageService.setItem('currentConversion', params);
  // }

  // get currentConversion(): ConversionResponseData {
  //   return this.sessionStorageService.getItem('currentConversion');
  // }
}
