import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class ValidatorsHelper {
  static collectErrorMessages(form: UntypedFormGroup, submitted = false, displayFieldName: boolean = false): Object {
    const errors = new Object();

    if (form.pristine && !form.touched && !submitted) {
      return errors;
    }

    const fields = Object.keys(form.value);
    fields.forEach((field: string) => {
      let fieldName = 'this field';
      if (displayFieldName) fieldName = `${field} field`;

      if (form.get(field).getError('onlyNumber')) {
        Object.assign(errors, { [field]: `${fieldName} must filled with number` });
      }
      if (form.get(field).getError('onlyDecimal')) {
        Object.assign(errors, { [field]: `${fieldName} must filled with number` });
      }
      if (form.get(field).getError('onlyRegularChar')) {
        Object.assign(errors, {
          [field]: `${fieldName} must filled only with regular text [a-z], score [-] and under score [_]`
        });
      }
      if (form.get(field).getError('url')) {
        Object.assign(errors, {
          [field]: `${fieldName} please enter valid url`
        });
      }
      if (form.get(field).hasError('required')) {
        Object.assign(errors, { [field]: `${fieldName} is required` });
      }
      if (form.get(field).hasError('email')) {
        Object.assign(errors, { [field]: `${fieldName} should contains valid email address` });
      }
      if (form.get(field).hasError('minlength')) {
        Object.assign(errors, {
          [field]: `The minimum length for ${fieldName} is ${
            form.get(field).getError('minlength').requiredLength
          } characters`
        });
      }
    });
    return errors;
  }
}
