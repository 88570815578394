import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { AuthService } from '@core/services/auth/auth.service';

export class CustomValidators {
  static regexNumber = /^[0-9]*$/;
  static regexDecimal = /^[0-9.]*$/;
  static regexRegularChar = /^[a-zA-Z\-\_\ ]*$/;
  static regexUrl =
    /^http(s?):\/\/((\w+.)?\w+.\w+\w.+|((2[0-5]{2}|1[0-9]{2}|[0-9]{1,2}).){3}(2[0-5]{3}|1[0-9]{3}|[0-9]{1,2})(:\d{1,5})?)(((\/)(.+)?)?)$/gm;

  static passwordMatch(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password: AbstractControl = control.get(passwordControlName);
      const confirmPassword: AbstractControl = control.get(confirmPasswordControlName);

      if (!password.value || !confirmPassword.value) {
        return null;
      }
      if (password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ matches: true });
      } else {
        confirmPassword.setErrors(null);
      }
      return null;
    };
  }

  static onlyDecimal(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp(CustomValidators.regexDecimal);
    return regex.test(control.value) ? null : { onlyDecimal: true };
  }

  static onlyNumber(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp(CustomValidators.regexNumber);
    return regex.test(control.value) ? null : { onlyNumber: true };
  }

  static onlyRegularChar(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp(CustomValidators.regexRegularChar);
    return regex.test(control.value) ? null : { onlyRegularChar: true };
  }

  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const phoneRegex = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,20}$/, 'i');
    return phoneRegex.test(control.value) ? null : { phone: true };
  }

  static url(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    // const urlRegex = new RegExp(
    //   /^(https?:\/\/)(www\.)?[-a-z0-9@:%._\+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()!@:%_\+.~#?&\/\/=]*)$/,
    //   'i'
    // );
    const urlRegex = new RegExp(CustomValidators.regexUrl);
    return urlRegex.test(control.value) ? null : { url: true };
  }

  static emailExists(authService: AuthService, role: string = 'Manager'): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return !control.value
        ? observableOf(null)
        : control.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            map((value: string) => value.trim()),
            switchMap((email) => authService.checkEmail({ email, role })),
            map((res) => (res ? { exists: true } : null)),
            first()
          );
    };
  }

  static companyExists(authService: AuthService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return !control.value
        ? observableOf(null)
        : control.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            map((value: string) => value.trim()),
            switchMap((company) => authService.checkCompany({ company })),
            map((res) => (res ? { exists: true } : null)),
            first()
          );
    };
  }

  static cityExists(authService: AuthService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return !control.value
        ? observableOf(null)
        : control.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            map((value: string) => value.trim()),
            switchMap((association) => authService.checkCity({ association })),
            map((res) => (res ? { exists: true } : null)),
            first()
          );
    };
  }

  static password(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.value;
      const isValid =
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(password);

      if (isValid) {
        return null; // Validation passes
      }
      return { invalidPassword: true }; // Validation fails
    };
  }

  static validCompanyName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }
}
