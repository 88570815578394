import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpReqInterceptorService } from './services/http-req-interceptor/http-req-interceptor.service';
import { FidlyServiceInterceptor } from '@buybutton/_interceptors';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { LYZI_HTTP_INTERCEPTORS } from './services/http-clients/lyzi-http-client.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: LYZI_HTTP_INTERCEPTORS, useClass: FidlyServiceInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpReqInterceptorService, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ]
})
export class CoreModule {}
