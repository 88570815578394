import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cgv',
  templateUrl: './cgv.component.html',
  styleUrls: ['./cgv.component.scss']
})
export class CgvComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
