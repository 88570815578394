/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

// Types
import { ImpersonationQueryParams } from '@shared/types/impersonation';

// Services
import { ImpersonationService } from '@core/services/impersonation/impersonation.service';
import { GlobalNotifierService } from '@core/services/global-notifier/global-notifier.service';

// Interfaces
import { ApiResponse } from '@core/interfaces/api-response';

/* -------------------------------------------------------------------------- */
/*                                 Component                                  */
/* -------------------------------------------------------------------------- */

@Component({
  selector: 'app-impersonation-signin',
  templateUrl: './impersonation-signin.component.html',
  styleUrls: ['./impersonation-signin.component.scss']
})
export class ImpersonationSigninComponent implements OnInit, OnDestroy {
  // Booleans
  isLoading: boolean = true;

  // Subs
  private impersonationSigninSub$: Subscription = new Subscription();

  // Others
  queryParams: ImpersonationQueryParams;

  constructor(
    private dialogRef: MatDialogRef<ImpersonationSigninComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { queryParams: ImpersonationQueryParams },
    private impersonationService: ImpersonationService,
    private router: Router,
    private globalNotifierService: GlobalNotifierService
  ) {}

  ngOnInit(): void {
    this.queryParams = this.data.queryParams;

    if (this.queryParams) {
      this.impersonationSigninSub$ = this.impersonationService.login(this.queryParams).subscribe({
        next: (apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.globalNotifierService.displayNotification(
              'success',
              'Succès',
              'Nouvelle session ouverte avec succès.'
            );
            this.router.navigate(['backoffice']);
            this.dialogRef.close();
            return;
          }

          this.dialogRef.close();
        },
        error: () => {
          this.globalNotifierService.displayNotification(
            'error',
            'Erreur',
            'Veuillez recharger la page des comptes et relancer la session une autre fois.',
            6000
          );
          this.router.navigate(['signin']);
          this.dialogRef.close();
        }
      });
    }
  }

  private unsubscribe(): void {
    this.impersonationSigninSub$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
