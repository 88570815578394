import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class GlobalNotifierService {
  constructor(private snackBar: MatSnackBar, private snotifyService: SnotifyService) {}

  displayNotification(
    type: 'success' | 'error' | 'info' | 'warning',
    title: string,
    description: string,
    duration: number = 2000
  ): void {
    this.snotifyService[type](description, title, {
      timeout: duration,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true
    });
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action);
  }
}
