import { Component, ElementRef, OnInit } from '@angular/core';

import { environment } from '@environments/environment';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <ng-snotify></ng-snotify>
  `
})
export class AppComponent implements OnInit {
  constructor(private _elementRef: ElementRef, private translate: TranslateService) {}

  ngOnInit() {
    if (environment.production) {
      (this._elementRef.nativeElement as HTMLElement).setAttribute('ng-version', '0.0.0-PLACEHOLDER');
    }

    this.translate.addLangs(['fr', 'en', 'es']);
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    // const browserLang = this.translate.getBrowserLang();
    // this.translate.use(browserLang.match(/en|es|fr/) ? browserLang : 'fr');
  }
}
