import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      alertMessage: string;
      redirectTo: string;
      buttonText: string;
    }
  ) {}

  ngOnInit() {}

  /**
   * Redirect to
   */
  redirectTo() {
    if (this.data.redirectTo) {
      this.router.navigate([this.data.redirectTo]);
    }
    this.dialogRef.close();
  }
}
