import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor() {}

  /**
   * Set item in sessionStorage
   * @param key: string - key
   * @param value: any - value
   */
  setItem(key: string, value: any) {
    sessionStorage.setItem(`${key}`, JSON.stringify(value));
  }

  /**
   * GetItem from sessionStorage
   * @param key: string
   */
  getItem(key: string) {
    return JSON.parse(sessionStorage.getItem(`${key}`));
  }

  /**
   * RemoveItem from sessionStorage
   * @param key: string
   */
  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  /**
   * Clear storage
   */
  clearStorage() {
    sessionStorage.clear();
  }
}
