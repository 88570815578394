import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalNotifierService } from '../global-notifier/global-notifier.service';
import { AuthService } from '../auth/auth.service';
import Errors from './errors_lang';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private readonly DEFAULT_ERROR_TITLE = `Oups ! Une erreur interne s'est produite`;

  constructor(private globalNotifServ: GlobalNotifierService, private authServ: AuthService, private router: Router) {}

  public handleError(errorResponse: any) {
    // display notification to let user know an error happened
    if (errorResponse instanceof HttpErrorResponse) {
      if (errorResponse.error.status === 401) {
        // log user out and redirect to signin
        this.authServ.signout().subscribe();
        return;
      }
      if (errorResponse.error.status === 500) {
        this.displayError(this.DEFAULT_ERROR_TITLE);
        // todo : send error to log service example sentry
        return;
      }

      const errors = Errors['fr'];

      const message = errors[errorResponse.error.code];

      if (message) {
        this.displayError(message);
      } else if (errorResponse.error.error) {
        this.displayError(errorResponse.error.error);
      } else {
        this.displayError(this.DEFAULT_ERROR_TITLE);
      }
    } else {
      this.displayError(this.DEFAULT_ERROR_TITLE);
      console.error('An error occured :', errorResponse);
    }
  }

  private displayError(message: string) {
    this.globalNotifServ.displayNotification('error', 'Erreur', message);
  }
}
