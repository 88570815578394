<mat-dialog-content class="fy-confirmation-dialog">
  <p [innerHTML]="data.confirmationMessage"></p>
  <mat-slide-toggle *ngIf="data.optionMessage" (change)="toggleChanged($event.checked)">{{data.optionMessage}}</mat-slide-toggle>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" [mat-dialog-close]="'cancel'">
    {{ 'shared.components.confirmation-dialog.cancel' | translate }}
  </button>
  <button mat-button cdkFocusInitial color="accent" [mat-dialog-close]="toggleStatus ? 'confirmedAndToggled' : 'confirm'">
    {{ 'shared.components.confirmation-dialog.submit' | translate }}
  </button>
</mat-dialog-actions>
