import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-conditions',
  templateUrl: './general-conditions.component.html',
  styleUrls: ['./general-conditions.component.scss']
})
export class GeneralConditionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
