import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { GlobalErrorHandlerService } from '../global-error-handler/global-error-handler.service';
import { environment } from '@environments/environment';
import { ApiResponse } from '@core/interfaces/api-response';

@Injectable({
  providedIn: 'root'
})
export class HttpReqInterceptorService {
  constructor(private authService: AuthService, private globalErrorHandlerService: GlobalErrorHandlerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 'GET_TOKEN_FROM_SERVICE'
    const userToken = this.authService.getStoredUserToken();
    const isFidlyEndpoint = req.url.includes(environment.apiURL);
    const isLoyaltyEndpoint = req.url.includes(environment.loyaltyApiURL);

    if (userToken && (isFidlyEndpoint || isLoyaltyEndpoint)) {
      req = req.clone({
        withCredentials: true,
        setHeaders: {
          Authorization: `Bearer ${userToken}`
        }
      });
    } else {
      req = req.clone({ withCredentials: true });
    }

    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        // Case: 401 throwd from an endpoint other then refresh token endpoint
        if (errorResponse.status === 401 && !errorResponse.url.includes('/refresh-token')) {
          // Refresh the token
          return this.authService.refreshToken().pipe(
            switchMap((apiResponse: ApiResponse) => {
              // Retry the original request with the new token if successful
              if (apiResponse.success) {
                const clonedRequest = req.clone({
                  withCredentials: true,
                  setHeaders: {
                    Authorization: `Bearer ${apiResponse.data.token}`
                  }
                });
                return next.handle(clonedRequest);
              }
            })
          );
        }

        this.globalErrorHandlerService.handleError(errorResponse);
      })
    );
  }
}
