import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private secretKey: string = environment.lyziCryptoSecret;

  constructor() {}

  /**
   * Encrypts the provided data using AES encryption.
   * @param data - The data to be encrypted.
   * @returns The encrypted data as a string.
   */
  encrypt(data: unknown): string {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();

    return ciphertext;
  }

  /**
   * Decrypts the provided encrypted data using AES decryption.
   * @param ciphertext - The encrypted data to be decrypted.
   * @returns The decrypted data as an object.
   */
  decrypt(ciphertext: string): any {
    const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedData;
  }
}
