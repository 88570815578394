import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { AuthService } from '@core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.authenticatedUser.pipe(
      take(1),
      switchMap((user) => (user ? observableOf(user) : this.authService.autoLogin())),
      map((user) => {
        if (!user) {
          return true;
        }
        switch (user.role) {
          case 'SuperAdmin':
            this.router.navigate(['admin']);
            break;
          case 'Manager':
          case 'SuperManager':
            this.router.navigate(['backoffice']);
            break;
          case 'Association':
            this.router.navigate(['city']);
            break;
          // case 'SimpleUser':
          //   this.router.navigate(['buy-button'], { queryParamsHandling: 'merge' });
          //   break;
          default:
            return true;
        }
        return false;
      })
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Promise<boolean> | Observable<boolean> {
    return this.authService.authenticatedUser.pipe(
      take(1),
      switchMap((user) => (!user ? this.authService.autoLogin() : observableOf(user))),
      map((user) => {
        const allowedRoles: string[] = route.data.allowedRoles;
        if (user && allowedRoles.includes(user.role)) {
          return true;
        }
        this.router.navigate(['signin']);
        return false;
      })
    );
  }
}
