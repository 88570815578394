import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AuthService } from '@core/services/auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  appVersion = environment.appVersion;
  resetPasswordForm: UntypedFormGroup;
  loader = false;

  constructor(private router: Router, private authServ: AuthService) {}

  ngOnInit() {
    this.resetPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email])
    });
  }

  get email() {
    return this.resetPasswordForm.get('email');
  }

  onSendResetPasswordRequest() {
    if (!this.resetPasswordForm.valid || this.loader) {
      return;
    }

    this.loader = true;
    this.authServ
      .sendResetPasswordRequest(this.resetPasswordForm.value)
      .pipe(
        tap(() => {
          this.router.navigate(['/reset-success'], {
            queryParams: this.resetPasswordForm.value
          });
        }),
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe();
  }
}
