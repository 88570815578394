import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

import { CustomValidators } from '@shared/validators/custom-validators';

import { GlobalNotifierService } from '@core/services/global-notifier/global-notifier.service';
import { AuthService } from '@core/services/auth/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  appVersion = environment.appVersion;

  signUpForm: UntypedFormGroup;
  passwordHidden = true;
  isLoading = false;
  ischecked: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private notifierService: GlobalNotifierService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.signUpForm = new UntypedFormGroup(
      {
        firstName: new UntypedFormControl(null, Validators.required),
        lastName: new UntypedFormControl(null, Validators.required),
        email: new UntypedFormControl(
          null,
          [Validators.required, Validators.email],
          CustomValidators.emailExists(this.authService, 'Manager')
        ),
        companyName: new UntypedFormControl(
          null,
          [Validators.required, Validators.minLength(2), CustomValidators.validCompanyName()],
          CustomValidators.companyExists(this.authService)
        ),
        password: new UntypedFormControl(null, [Validators.required, CustomValidators.password()]),
        confirmPassword: new UntypedFormControl(null, [Validators.required, CustomValidators.password()]),
        role: new UntypedFormControl('Manager', Validators.required),
        acceptConditions: new UntypedFormControl(false, Validators.requiredTrue)
        // entity: new FormControl(null)
      },
      {
        validators: CustomValidators.passwordMatch('password', 'confirmPassword')
      }
    );


    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    console.log(queryParams)
    if (queryParams['super'] == '1') {
      this.onClick()
    }
  }

  get firstName(): AbstractControl {
    return this.signUpForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.signUpForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.signUpForm.get('email');
  }

  get companyName(): AbstractControl {
    return this.signUpForm.get('companyName');
  }

  get password(): AbstractControl {
    return this.signUpForm.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.signUpForm.get('confirmPassword');
  }

  get acceptConditions(): AbstractControl {
    return this.signUpForm.get('acceptConditions');
  }
  // get entity(): AbstractControl {
  //   return this.signUpForm.get('entity');
  // }
  // get role(): AbstractControl {
  //   return this.signUpForm.get('role');
  // }

  onClick(): void {
    this.ischecked = !this.ischecked;
    if (this.ischecked) {
      console.log('parent company exist');
      this.signUpForm.patchValue({
        role: 'SuperManager'
      });
    } else {
      this.signUpForm.patchValue({
        role: 'Manager'
      });
    }
  }
  onSignUp(): void {
    if (!this.signUpForm.valid || this.isLoading) {
      return;
    }

    let role: string;
    this.isLoading = true;

    this.authService
      .signup({
        ...this.signUpForm.value,
        confirmPassword: undefined,
        acceptConditions: undefined
      })
      .pipe(
        switchMap(({ user }) => {
          console.log(user);
          role = user.role;
          return this.translate.get('public.signup.success');
        }),
        tap((success) => {
          this.notifierService.displayNotification('success', success.title, success.body);
          this.router.navigate(['signin']);
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }
}
