import { HttpClient, HttpBackend, HttpInterceptor } from '@angular/common/http';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';

export const LYZI_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>('LYZI_HTTP_INTERCEPTORS');

@Injectable({
  providedIn: 'root'
})
export class LyziHttpClientService extends HttpClient {
  constructor(backend: HttpBackend, private injector: Injector) {
    super(new HttpHandlerService(backend, injector, LYZI_HTTP_INTERCEPTORS));
  }
}
