<div class="fy-conditions">
  <div class="fy-conditions__header">
    <div class="fy-conditions__header__img">
      <img src="/assets/icons/lyzi.svg" />
    </div>

    <div class="fy-conditions__header__txt">
      <span><strong>Conditions générales de vente du Logiciel de fidélité « Lyzi »</strong> </span><br />
      <span>en vigueur au 01 Janvier 2020</span>
    </div>
  </div>

  <div class="fy-conditions__content">
    <!-- PREAMBULE -->
    <section class="fy-section">
      <span class="fy-section__title">PREAMBULE</span>
      <p class="fy-section__para">
        Les présentes Conditions Générales déterminent les règles mutuelles entre la société Proofeo et les partenaires/
        commerçants commerçants. La plateforme Lyzi est la propriété exclusive de la Société Proofeo société par actions
        simplifiée.
      </p>

      <p class="fy-section__para">
        La plateforme permet à des commerçants de souscrire à la solution Lyzi. . Celle-ci Celle consiste en la
        génération de point de fidélités dématérialisées auprès de partenaires/ commerçants. Les utilisateurs peuvent
        changer ou céder les points fidélités en les transmettant en les transformant en crypto monnaie ou monnaie
        fiduciaire.
      </p>

      <p class="fy-section__para">Les souscripteurs seront nommés “Partenaires”</p>

      <p class="fy-section__para">
        Les souscripteurs de la solution sont réputés accepter les conditions générales en vigueur du seul fait du
        paiement sur la plateforme.
      </p>
    </section>

    <!-- ARTICLE 1. CHAMP D’APPLICATION – MODIFICATION -->
    <section class="fy-section">
      <span class="fy-section__title">ARTICLE 1. CHAMP D’APPLICATION – MODIFICATION</span>
      <p class="fy-section__para">
        Les présentes conditions générales de ven vente s’appliquent sans restriction, ni réserve, à l’ensemble du
        service proposé par le site internet sauf conditions spécifiques applicables à certains Services.
      </p>

      <p class="fy-section__para">
        Le commerçant ayant souscrit au paiement de la solution déclare et reconnaît avoir lu les présentes conditions
        générales de vente dans leur intégralité.
      </p>
    </section>

    <!-- ARTICLE 2. INSCRIPTION ET ACCÈS A LA SOLUTION -->
    <section class="fy-section">
      <span class="fy-section__title">ARTICLE 2. INSCRIPTION ET ACCÈS A LA SOLUTION</span>
      <div class="fy-section__sub-title">
        <span>2.1 Modalités de souscription</span>
      </div>

      <p class="fy-section__para">
        Pour accéder à l’inscription et ainsi au paiement de la souscription, le commerçant doit remplir un formulaire
        d’inscription en fournissant des informations personnelles qui permettront ensuite de l’identifier. Celui-ci
        s’engage à fournir des informations vraies, exactes et complètes sur le dit formulaire.
      </p>

      <p class="fy-section__para">
        La société Proofeo, propriétaire de la présente plateforme se réserve le droit de demander à tout potentiel
        commerçant ayant rempli le formulaire d’inscription de justifier par tout moyen de l’identité de sa personne
        morale.
      </p>

      <p class="fy-section__para">
        Par ailleurs, la société Proofeo n’ayant pas pour obligation et ne disposant pas des moyens techniques de
        s’assurer de l’identité des visiteurs s’inscrivant sur la plateforme, sa responsabilité ne peut être engagée
        dans l’hypothèse d’une usurpation d’identité.
      </p>

      <div class="fy-section__sub-title">
        <span>2.2 Principes généraux</span>
      </div>

      <p class="fy-section__para">Le souscripteur déclare, préalablement à son inscription à la solution :</p>

      <p class="fy-section__para">
        Avoir pouvoir d’agir au nom de la personne morale qui souscrit à la solution, qu’il est une personne majeure et
        qu’il dispose de la capacité et de la volonté lui permettant de s'engager contractuellement.
      </p>
    </section>

    <!-- ARTICLE 3. DONNEES PERSONNELLES -->
    <section class="fy-section">
      <span class="fy-section__title">ARTICLE 3. DONNEES PERSONNELLES</span>
      <div class="fy-section__sub-title">
        <span>3.1 Collecte et protection des données personnelles</span>
      </div>

      <p class="fy-section__para">
        Les données nominatives collectées et ultérieurement traitées par la société Proofeo sont celles que le
        souscripteur transmet volontairement au site notamment via le formulaire de souscription.
      </p>

      <p class="fy-section__para">
        Les données collectées, traitées et conservées sont d'une part, les données, qui sont transmises volontairement
        par le souscripteur lors de son inscription à la solution ou ultérieurement dans le cadre de mises à jour des
        informations le concernant et d'autre part, les données collectées automatiquement par le site par le biais de
        Cookies.
      </p>

      <p class="fy-section__para">Les données personnelles sont stockées chez l'hébergeur de la plateforme.</p>

      <p class="fy-section__para">
        Les données sont conservées pour la durée de un an ou toute durée légale ou réglementaire maximale et impérative
        qui pourrait être substituée à la durée précitée. Au-delà des périodes mentionnées ci-dessus, les données et les
        données collectées automatiquement par le site seront conservées sous forme anonyme, à des fins exclusivement
        statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit.
      </p>

      <div class="fy-section__sub-title">
        <span>3.2 Cookies</span>
      </div>

      <p class="fy-section__para">
        Le visiteur du site internet est informé de la faculté laissée à la société Proofeo de mettre en place des
        collecteurs automatisés de données (« cookies ») afin notamment d’identifier l’utilisateur et ses visites
        successives sur le Site et d’améliorer le Service.
      </p>

      <p class="fy-section__para">
        Le cookie est un fichier informatique, stocké pour une durée limitée sur le disque dur de l’ordinateur de
        l’Utilisateur. L’utilisateur conserve toutefois la possibilité de s’opposer à l’enregistrement des cookies.
      </p>

      <div class="fy-section__sub-title">
        <span>3.3 Exonération de responsabilité</span>
      </div>

      <p class="fy-section__para">
        La société Proofeo s’engage à prendre des précautions techniques reconnues afin de préserver la sécurité des
        données à caractère personnel et notamment empêcher qu'elles ne soient déformées, endommagées ou communiquées à
        des tiers non autorisés.
      </p>

      <p class="fy-section__para">
        Néanmoins chaque visiteur du site reconnaît qu’en l’état de la technique actuelle, chaque fois qu’il fournit des
        informations personnelles en ligne, ces informations peuvent être collectées et utilisées par des tiers.
      </p>

      <p class="fy-section__para">
        Dès lors, l’utilisateur décharge la société Proofeo de toute responsabilité ou conséquence dommageable de
        l’utilisation par des tiers des informations échangées par le biais des outils proposés par la plateforme.
      </p>
    </section>

    <!-- ARTICLE 4. ENGAGEMENT ET RESPONSABILITE DES PARTIES -->
    <section class="fy-section">
      <span class="fy-section__title">ARTICLE 4. ENGAGEMENT ET RESPONSABILITE DES PARTIES</span>
      <div class="fy-section__sub-title">
        <span>4.1 Obligation de la société Proofeo</span>
      </div>
      <p class="fy-section__para">
        La société Proofeo s’engage à mettre en place la solution pour le commerçant souscripteur et à gérer la solution
        pour ses propres clients mais aussi pour les clients acquis à partir de l’application Lyzi.
      </p>

      <p class="fy-section__para">
        La société Proofeo s’engage à assister le souscripteur de la solution dans la mise en place et l’utilisation de
        la solution en question.
      </p>

      <p class="fy-section__para">
        La société Proofeo s’engage à prendre en charge de par la solution la génération de FID et est garant de la
        bonne marche de la solution vis à vis des clients des souscripteurs.
      </p>

      <p class="fy-section__para">
        La société Proofeo s’engage à prendre en charge de par la solution la génération de FID et est garant de la
        bonne marche de la solution vis à vis des clients des souscripteurs.
      </p>
      <div class="fy-section__sub-title">
        <span>4.2 Obligation du commerçant/ souscripteur</span>
      </div>

      <p class="fy-section__para">
        Le commerçant qui a souscrit par le biais du site internet à l’achat de la solution verse la somme définie dans
        le cadre du forfait choisi. Le souscripteur ne pourra après paiement sur le site demander le remboursement de la
        souscription.
      </p>

      <p class="fy-section__para">
        Pour autant, le souscripteur peut résilier son abonnement en envoyant un email à l’adresse contact@proofeo.io.
        Aucun remboursement ne pourra être exigé.
      </p>
    </section>

    <!-- ARTICLE 5. DUREE -->
    <section class="fy-section">
      <span class="fy-section__title">ARTICLE 5. DUREE</span>
      <p class="fy-section__para">
        Ces conditions encadrent l’achat via le site pour une durée de trois ans à compter de l’achat.
      </p>
    </section>

    <!-- ARTICLE 6. POLITIQUE DE CONFIDENTIALITE -->
    <section class="fy-section">
      <span class="fy-section__title">ARTICLE 6. POLITIQUE DE CONFIDENTIALITE</span>
      <p class="fy-section__para">
        La société Proofeo considérera comme strictement confidentiel, et s'interdit de divulguer, toute information,
        document, donnée ou concept, dont il pourra avoir connaissance à l'occasion de la souscription du commerçant.
        Pour l'application de la présente clause, le prestataire répondde ses salariés comme de lui-même. La société
        Proofeo, toutefois, ne saurait être tenu pour responsable d'aucune divulgation si les éléments divulgués étaient
        dans le domaine public à la date de la divulgation, ou s'il en avait déjà connaissance antérieurement à la date
        de l’acceptation de ces différentes conditions générales, ou s'il les obtient de tiers par des moyens légitimes.
      </p>
    </section>

    <!-- ARTICLE 7. RESILIATION / SANCTION -->
    <section class="fy-section">
      <span class="fy-section__title">ARTICLE 7. RESILIATION / SANCTION</span>
      <p class="fy-section__para">
        Tout manquement de l'une ou l'autre des parties aux obligations qu'elle a en charge, entraînera, si bon semble
        au créancier de l'obligation inexécutée, la résiliation de plein droit au présent contrat, quinze jours après
        mise en demeure d'exécuter par lettre recommandée avec accusé de réception demeurée sans effet, sans préjudice
        de tous dommages et intérêts.
      </p>

      <p class="fy-section__para">Cela n'entraînera toutefois pas le remboursement de la solution.</p>
    </section>

    <!-- ARTICLE 8. LOI APPLICABLE ET COMPETENCE -->
    <section class="fy-section">
      <span class="fy-section__title">ARTICLE 8. LOI APPLICABLE ET COMPETENCE</span>
      <p class="fy-section__para">Les présentes conditions générales sont soumises au droit français.</p>

      <p class="fy-section__para">
        En cas de litige entre les parties du fait de l’exécution ou de l’interprétation des présentes CG, les parties
        conviennent expressément de recourir à la médiation.
      </p>

      <p class="fy-section__para">
        En cas d’échec de la médiation, les différends découlant des présentes CG seront tranchés par le Tribunal
        compétent conformément aux règles de droit commun sauf si l’Utilisateur a la qualité de commerçant.
      </p>

      <p class="fy-section__para">
        Pour les commerçants, tout différend découlant des présentes CG (conclusion, l'interprétation, l'exécution et
        cessation des présentes) sera soumis à la compétence exclusive du Tribunal de Commerce de Nanterre, même en cas
        d’appel en garantie ou de pluralité de défendeurs.
      </p>
    </section>

    <!-- SECTION EXAMPLE -->
    <!-- <section class="fy-section">
      <span class="fy-section__title">ARTICLE 2. INSCRIPTION ET ACCÈS A LA SOLUTION</span>
      <div class="fy-section__sub-title">
        <span>2.1 Modalités de souscription</span>
      </div>

      <p class="fy-section__para">

      </p>

      <p class="fy-section__para">

      </p>

      <p class="fy-section__para">

      </p>

      <p class="fy-section__para">

      </p>
    </section> -->
  </div>
</div>
