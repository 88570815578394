<div class="fy-not-found fy-public-page">
  <div class="fy-public-page__content">
    <div class="fy-not-found__header">
      <div class="fy-not-found__header__logo">
        <img src="assets/icons/lyzi.svg" />
      </div>
      <p class="fy-not-found__header__title" translate>public.not-found.title</p>
      <p class="fy-not-found__header__subtitle" [routerLink]="['/']" routerLinkActive="router-link-active" translate>
        public.not-found.subtitle
      </p>
    </div>
  </div>
</div>
