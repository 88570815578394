<div class="fy-reset-success fy-public-page">
  <div class="fy-public-page__content">
    <div class="fy-reset-success__header">
      <div class="fy-reset-success__header__logo">
        <img src="assets/icons/lyzi.svg" />
      </div>
      <div class="fy-reset-success__header__logo">
        <img class="fy-reset-success__header__logo__done-img" src="assets/icons/done.png" />
      </div>
    </div>

    <div class="fy-reset-success__success-text-box">
      <p class="fy-reset-success__success-text-box__title" translate>public.reset-success.title</p>
      <p class="fy-reset-success__success-text-box__subtitle" translate>
        public.reset-success.subtitle.0 <strong>{{ email }}</strong
        >.
      </p>
      <p class="fy-reset-success__success-text-box__subtitle" translate>public.reset-success.subtitle.1</p>
    </div>

    <div class="fy-reset-success__fait-box">
      <button mat-raised-button class="fy-button" [routerLink]="['/signin']">
        {{ 'public.reset-success.signin' | translate }}
      </button>
    </div>
  </div>
</div>
