import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: "https://a7bd8cba51954761ac61587abb82f3f9@o1347078.ingest.sentry.io/6716654",
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["https://api.lyzi.fr/api", "https://lizy-prod-1.digital-broker.net", "localhost"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
