<div class="fy-otp fy-public-page">
  <div class="fy-public-page__content">
    <div class="fy-otp__header">
      <div class="fy-otp__header__logo">
        <img src="/assets/icons/lyzi.svg" alt="lyzi logo" />
      </div>
      <p class="fy-otp__header__title" translate>public.otp.title</p>
      <p class="fy-otp__header__subtitle" translate>public.otp.subtitle</p>
    </div>

    <div class="fy-form">
      <form class="fy-form__content" role="form">
        <!-- Code field -->
        <mat-form-field>
          <mat-label translate>forms.code.label</mat-label>
          <input matInput [formControl]="code" type="number" maxlength="6" />
          <mat-error *ngIf="otpForm.errors?.required" translate>forms.code.errors.required</mat-error>
        </mat-form-field>

        <div class="fy-otp__controls-box">
          <button
            mat-raised-button
            class="fy-button"
            [disabled]="otpForm.invalid || isLoading"
            (click)="validate()"
            type="submit"
          >
            <mat-spinner *ngIf="isLoading" [strokeWidth]="2" [diameter]="30"></mat-spinner>
            <span *ngIf="!isLoading" translate>public.otp.submit</span>
          </button>

          <div class="fy-otp__controls-box__otp-link">
            <a class="terms-txt" routerLink="/signin" translate>public.otp.return</a>
          </div>
        </div>
      </form>
    </div>

    <div class="fy-otp__footer">
      <span translate>public.otp.version</span>&nbsp;
      <span>{{ appVersion }}</span>
    </div>
  </div>
</div>
