import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

import { CustomValidators } from '@shared/validators/custom-validators';

import { GlobalNotifierService } from '@core/services/global-notifier/global-notifier.service';
import { AuthService } from '@core/services/auth/auth.service';

@Component({
  selector: 'app-city-signup',
  templateUrl: './city-signup.component.html',
  styleUrls: ['./city-signup.component.scss']
})
export class CitySignupComponent implements OnInit {
  appVersion = environment.appVersion;

  signUpForm: UntypedFormGroup;
  passwordHidden = true;
  isLoading = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private notifierService: GlobalNotifierService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.signUpForm = new UntypedFormGroup(
      {
        associationName: new UntypedFormControl(
          null,
          Validators.required,
          CustomValidators.cityExists(this.authService)
        ),
        firstName: new UntypedFormControl(null, Validators.required),
        lastName: new UntypedFormControl(null, Validators.required),
        email: new UntypedFormControl(
          null,
          [Validators.required, Validators.email],
          CustomValidators.emailExists(this.authService, 'Association')
        ),
        password: new UntypedFormControl(null, [Validators.required, CustomValidators.password()]),
        confirmPassword: new UntypedFormControl(null, [Validators.required, CustomValidators.password()]),
        role: new UntypedFormControl('Association', Validators.required),
        acceptConditions: new UntypedFormControl(false, Validators.requiredTrue)
      },
      {
        validators: CustomValidators.passwordMatch('password', 'confirmPassword')
      }
    );
  }

  get association(): AbstractControl {
    return this.signUpForm.get('associationName');
  }

  get firstName(): AbstractControl {
    return this.signUpForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.signUpForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.signUpForm.get('email');
  }

  get password(): AbstractControl {
    return this.signUpForm.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.signUpForm.get('confirmPassword');
  }

  get acceptConditions(): AbstractControl {
    return this.signUpForm.get('acceptConditions');
  }

  onSignUp(): void {
    if (!this.signUpForm.valid || this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.authService
      .signup({
        ...this.signUpForm.value,
        confirmPassword: undefined,
        acceptConditions: undefined
      })
      .pipe(
        switchMap(() => this.translate.get('public.city-signup.success')),
        tap((success) => {
          this.notifierService.displayNotification('success', success.title, success.body);
          this.router.navigate(['/', 'city']);
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }
}
