import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { City, User } from '../../models/user';
import { ApiResponse } from '../../interfaces/api-response';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { UserService } from '@backoffice/services/user/user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authenticatedUser$ = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
    private sessionStorageServ: SessionStorageService,
    private userService: UserService,
    private router: Router
  ) {}

  get authenticatedUser(): Observable<User> {
    return this.authenticatedUser$.asObservable();
  }

  set user(user: User) {
    this.authenticatedUser$.next(user);
  }

  storeUser(data: { user: User; token: string }): void {
    if(data?.token) {
      this.sessionStorageServ.setItem('token', data.token);
      this.sessionStorageServ.setItem('user', data.user);
      this.authenticatedUser$.next(data.user);
      this.userService.updateStoredUser(data.user);
    }
  }

  signup(signUpData: any): Observable<{ user: User; token: string }> {
    return this.http.post<ApiResponse>(environment.apiURL + 'auth/register', signUpData).pipe(
      map((res) => res.data),
      tap(this.storeUser.bind(this))
    );
  }

  signin(signInData: { email: string; password: string }): Observable<{ user: User; token: string }> {
    return this.http
      .post<ApiResponse>(environment.apiURL + 'auth/login/manager', { ...signInData, origin: 'dashboard' })
      .pipe(
        map((res) => res.data),
        tap(this.storeUser.bind(this))
      );
  }

  getUser(): Observable<User> {
    return this.http.get<ApiResponse>(environment.apiURL + 'users/me').pipe(
      map((res) => res.data),
      tap(this.storeUser.bind(this)),
      catchError(() => observableOf(null))
    );
  }

  updateUser(): Observable<User> {
    return this.http.get<ApiResponse>(environment.apiURL + 'users/me').pipe(
      map((res) => res.data as User),
      tap((user) => {
        this.sessionStorageServ.setItem('user', user);
        this.authenticatedUser$.next(user);
        this.userService.updateStoredUser(user);
      })
    );
  }

  autoLogin(): Observable<User> {
    const token = this.getStoredUserToken();

    if (!!token) {
      return this.http.get<ApiResponse>(environment.apiURL + 'users/me').pipe(
        map((res) => res.data as User),
        tap((user) => {
          this.sessionStorageServ.setItem('user', user);
          this.authenticatedUser$.next(user);
        }),
        catchError(() => observableOf(null))
      );
    }
    return observableOf(null);
  }

  getCities(name: string): Observable<City[]> {
    return this.http.post<ApiResponse>(environment.apiURL + 'cities', { name }).pipe(
      map((res) => res.data),
      catchError(() => observableOf([]))
    );
  }

  getStoredUserToken(): string {
    const token = this.sessionStorageServ.getItem('token') as string;
    return token ? token : null;
  }

  checkEmail(emailData: { email: string; role: string }): Observable<boolean> {
    return this.http.post<ApiResponse>(environment.apiURL + 'auth/check/email', emailData).pipe(
      map((res) => !!res.data?.found),
      catchError(() => observableOf(false))
    );
  }

  checkCompany(companyData: { company: string }): Observable<boolean> {
    return this.http.post<ApiResponse>(environment.apiURL + 'auth/check/company', companyData).pipe(
      map((res) => !!res.data?.found),
      catchError(() => observableOf(false))
    );
  }

  checkCity(cityData: { association: string }): Observable<boolean> {
    return this.http.post<ApiResponse>(environment.apiURL + 'auth/check/association', cityData).pipe(
      map((res) => !!res.data?.found),
      catchError(() => observableOf(false))
    );
  }

  sendResetPasswordRequest(resetData: { email: string }): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(environment.apiURL + 'auth/reset-password/send', resetData);
  }

  signout(): Observable<ApiResponse> {
    const user: User = this.sessionStorageServ.getItem('user');

    return this.http
      .post<ApiResponse>(environment.apiURL + `auth/sign-out`, {}, { headers: { 'X-User-Id': user?._id } })
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            let isImpersonated: boolean = false;
            this.authenticatedUser$.subscribe({
              next: (user: User) => {
                if (user?.isImpersonated) isImpersonated = true;
              }
            });
            this.authenticatedUser$.next(null);
            this.sessionStorageServ.clearStorage();

            // Case impersonated user => close session
            if (isImpersonated) {
              window.close();
              return;
            }

            // Case not impersonated user => redirect to sign in page
            this.router.navigateByUrl('/signin', {
              replaceUrl: true
            });
          }
          return apiResponse;
        })
      );
  }

  validateUserOTP(payload: { userId: string; otp: string, emailOtp: string }): Observable<{ user: User; token: string }> {
    return this.http.post<ApiResponse>(environment.apiURL + 'auth/otp', { ...payload }).pipe(
      map((res) => res.data),
      tap(this.storeUser.bind(this))
    );
  }

  toggleTwoFactorOTP(payload: { userId: string; enableOTP: boolean }): Observable<User> {
    return this.http.post<ApiResponse>(environment.apiURL + 'auth/toggle-two-factor-otp', { ...payload }).pipe(
      map((res) => res.data as User),
      tap((user) => {
        this.sessionStorageServ.setItem('user', user);
        this.authenticatedUser$.next(user);
      })
    );
  }

  /**
   * Refresh expired acess token.
   *
   */
  refreshToken(): Observable<ApiResponse> {
    const user: User = this.sessionStorageServ.getItem('user');

    return this.http
      .post<ApiResponse>(environment.apiURL + `auth/refresh-token`, {}, { headers: { 'X-User-Id': user?._id } })
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.sessionStorageServ.setItem('token', apiResponse.data.token);
          }

          return apiResponse;
        })
      );
  }
}
