<div class="fy-reset-password fy-public-page">
  <div class="fy-public-page__content">
    <div class="fy-reset-password__header">
      <div class="fy-reset-password__header__logo">
        <img src="assets/icons/lyzi.svg" alt="lyzi logo" />
      </div>
      <p class="fy-reset-password__header__title" translate>public.reset-password.title</p>
    </div>

    <div class="fy-reset-password__reset-text-box">
      <p class="fy-reset-password__reset-text-box__subtitle" translate>public.reset-password.subtitle</p>
    </div>

    <div class="fy-form">
      <form class="fy-form__content" role="form">
        <!-- Email field -->
        <mat-form-field>
          <mat-label translate>forms.email.label</mat-label>
          <input matInput [formControl]="email" type="email" />
          <mat-error *ngIf="email.errors?.required" translate>forms.email.errors.required</mat-error>
          <mat-error *ngIf="email.errors?.email" translate>forms.email.errors.email</mat-error>
        </mat-form-field>
      </form>
    </div>

    <div class="fy-reset-password__reset-box">
      <button
        mat-raised-button
        class="fy-button"
        [disabled]="!resetPasswordForm.valid || loader"
        (click)="onSendResetPasswordRequest()"
      >
        {{ 'public.reset-password.submit' | translate }}
      </button>
      <div class="fy-reset-password__reset-box__connectez-vous">
        <span class="qst-text" translate>public.reset-password.remember</span>&nbsp;
        <span class="terms-txt" [routerLink]="['/signin']" translate>public.reset-password.signin</span>
      </div>
    </div>
    <div class="fy-reset-password__footer">
      <span>{{ 'public.reset-password.version' | translate }} {{ appVersion }}</span>
    </div>
  </div>
</div>
