import { Injectable } from '@angular/core';

// Services
import { CryptoService } from '@core/services/crypto/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor(private cryptoService: CryptoService) {}

  /**
   * Set item in session storage
   * @param key: string - key
   * @param value: any - value
   */
  setItem(key: string, value: any) {
    const ciphertext = this.cryptoService.encrypt(value);
    sessionStorage.setItem(key, ciphertext);
  }

  /**
   * GetItem from session storage
   * @param key: string
   */
  getItem(key: string) {
    const ciphertext = sessionStorage.getItem(key);

    if (!ciphertext) return null;

    const decryptedData = this.cryptoService.decrypt(ciphertext);

    return decryptedData;
  }

  /**
   * RemoveItem from session storage
   * @param key: string
   */
  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  /**
   * Clear storage
   */
  clearStorage() {
    sessionStorage.clear();
  }
}
