import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class IntlPaginator extends MatPaginatorIntl {
  ofPreposition: string;

  constructor(private translate: TranslateService) {
    super();
    this.translate
      .get('shared.components.paginator')
      .pipe(
        tap((paginator) => {
          this.itemsPerPageLabel = paginator.itemsPerPageLabel;
          this.nextPageLabel = paginator.nextPageLabel;
          this.previousPageLabel = paginator.previousPageLabel;
          this.firstPageLabel = paginator.firstPageLabel;
          this.lastPageLabel = paginator.lastPageLabel;
          this.ofPreposition = paginator.of;
        })
      )
      .subscribe();
  }

  getRangeLabel: (page: number, pageSize: number, length: number) => string = (
    page: number,
    pageSize: number,
    length: number
  ) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofPreposition} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} – ${endIndex} ${this.ofPreposition} ${length}`;
  };
}
