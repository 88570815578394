import { Injectable } from '@angular/core';
import { ConversionHelper } from '@buybutton/_helpers';
import {
  ConfirmResetPasswordParams,
  FidlySuccessResponse,
  ForgotPasswordParams,
  ISocialLogin,
  SigninParams
} from '@buybutton/_interface';
import { ApiResponse } from '@core/interfaces/api-response';
import { User } from '@core/models/user';
import { LyziHttpClientService } from '@core/services/http-clients/lyzi-http-client.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authenticatedClient$ = new BehaviorSubject<User>(null);

  constructor(private lyziHttp: LyziHttpClientService, private sessionStorageServ: SessionStorageService) {}

  signin(data: SigninParams): Observable<any> {
    return this.lyziHttp
      .post<FidlySuccessResponse>(
        environment.apiURL + 'auth/login',
        {
          email: data.email,
          password: data.password
        },
        { headers: { 'X-User-Agent': 'dashboard' } }
      )
      .pipe(
        map((resp) => resp.data),
        tap((resp) => {
          this.fidlyClientToken = resp.token;
          this.authenticatedClient$.next(resp.user);
        })
      );
  }

  socialLogin(data: ISocialLogin): Observable<any> {
    console.log('social login service');
    return this.lyziHttp
      .post<FidlySuccessResponse>(environment.apiURL + 'auth/social', data, {
        headers: { 'X-User-Agent': 'dashboard' }
      })
      .pipe(
        map((resp) => resp.data),
        tap((resp) => {
          this.fidlyClientToken = resp.token;
          this.authenticatedClient$.next(resp.user);
        })
      );
  }

  autoSignin(): Observable<User> {
    const fidlyClientToken = this.fidlyClientToken;

    if (fidlyClientToken) {
      return this.getUser().pipe(catchError(() => of(null)));
    }
    return of(null);
  }

  getUser(): Observable<User> {
    return this.lyziHttp.get(environment.apiURL + 'users/me').pipe(
      map((res: any) => res.data as User),
      tap((user) => {
        this.client = user;
      })
    );
  }

  controlCollector(identifier: string): Observable<any> {
    return this.lyziHttp
      .post<FidlySuccessResponse>(environment.apiURL + 'collectors/controlcollector', {
        identifier,
        os: 'browser',
        osVersion: 'unknown',
        deviceModel: ConversionHelper.getBrowserName(),
        deviceToken: navigator.userAgent
      })
      .pipe(map((resp) => resp.data));
  }

  forgotPassword(data: ForgotPasswordParams): Observable<any> {
    return this.lyziHttp
      .post<FidlySuccessResponse>(environment.apiURL + 'auth/reset-password/send', {
        email: data.email,
        origin: 'application'
      })
      .pipe(
        map((resp) => resp.data),
        tap((resp) => {
          this.fidlyClientToken = resp.token;
        })
      );
  }

  confirmResetPassword(data: ConfirmResetPasswordParams): Observable<any> {
    return this.lyziHttp.post<FidlySuccessResponse>(
      environment.apiURL + 'auth/reset-password',
      {
        password: data.password,
        token: data.fidlyToken
      },
      {
        responseType: 'blob' as 'json'
      }
    );
  }

  signout(): Observable<ApiResponse> {
    const user: User = this.sessionStorageServ.getItem('user');

    return this.lyziHttp
      .post<ApiResponse>(environment.apiURL + `auth/sign-out`, {}, { headers: { 'X-User-Id': user?._id } })
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.authenticatedClient$.next(null);
            this.sessionStorageServ.clearStorage();
            location.reload();
          }
          return apiResponse;
        })
      );
  }

  set fidlyClientToken(val: string) {
    this.sessionStorageServ.setItem('fidlyClientToken', val);
  }

  get fidlyClientToken(): string {
    return this.sessionStorageServ.getItem('fidlyClientToken');
  }

  set brokerClientToken(val: string) {
    this.sessionStorageServ.setItem('brokerClientToken', val);
  }

  get brokerClientToken(): string {
    return this.sessionStorageServ.getItem('brokerClientToken');
  }

  set client(user: User) {
    this.sessionStorageServ.setItem('client', user);
    this.authenticatedClient$.next(user);
  }

  get client(): User {
    return this.sessionStorageServ.getItem('client');
  }

  get authenticatedClient(): Observable<User> {
    return this.authenticatedClient$.asObservable();
  }

  /**
   * Refresh expired acess token.
   *
   */
  refreshToken(): Observable<ApiResponse> {
    const user: User = this.sessionStorageServ.getItem('user');

    return this.lyziHttp
      .post<ApiResponse>(environment.apiURL + `auth/refresh-token`, {}, { headers: { 'X-User-Id': user?._id } })
      .pipe(
        map((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.sessionStorageServ.setItem('fidlyClientToken', apiResponse.data.token);
          }

          return apiResponse;
        })
      );
  }
}
