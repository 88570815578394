/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';

// Components
import { AppComponent } from './app.component';
import { CgvComponent } from '@public/cgv/cgv.component';
import { NotFoundComponent } from '@public/not-found/not-found.component';
import { ResetPasswordComponent } from '@public/reset-password/reset-password.component';
import { ResetSuccessComponent } from '@public/reset-success/reset-success.component';
import { SigninComponent } from '@public/signin/signin.component';
import { SignupComponent } from '@public/signup/signup.component';
import { CitySignupComponent } from '@public/city-signup/city-signup.component';
import { ImpersonationSigninComponent } from '@public/impersonation-signin/impersonation-signin.component';

// Others
import { environment } from '../environments/environment';
import { OtpComponent } from './public/otp/otp.component';

/* -------------------------------------------------------------------------- */
/*                                   Module                                   */
/* -------------------------------------------------------------------------- */

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ResetPasswordComponent,
    SigninComponent,
    OtpComponent,
    SignupComponent,
    CitySignupComponent,
    ResetSuccessComponent,
    CgvComponent,
    ImpersonationSigninComponent,
    OtpComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SnotifyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    SharedModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth())
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
