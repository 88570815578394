/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { NgxStripeModule } from '@nomadreservations/ngx-stripe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Pipes
import { TruncatePipe } from './pipes/truncate.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';

// Components
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { AddBoxComponent } from './components/add-box/add-box.component';
import { GeneralConditionsComponent } from './components/general-conditions/general-conditions.component';
import { CguComponent } from './components/cgu/cgu.component';
import { TablePlaceholderComponent } from './components/table-placeholder/table-placeholder.component';
import { LabelComponent } from './components/label/label.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { DailyTransferDetailsComponent } from './components/daily-transfer-details/daily-transfer-details.component';

// Services
import { IntlDateAdapter } from './services/intl-date-adapter.service';
import { IntlPaginator } from './services/intl-paginator.service';

// Envs
import { environment } from '@environments/environment';

/* -------------------------------------------------------------------------- */
/*                               Shared Module                                */
/* -------------------------------------------------------------------------- */

@NgModule({
  declarations: [
    CapitalizePipe,
    GaugeChartComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    UploadImageComponent,
    AddBoxComponent,
    GeneralConditionsComponent,
    CguComponent,
    TablePlaceholderComponent,
    LabelComponent,
    NavbarComponent,
    TruncatePipe,
    NotificationMenuComponent,
    DailyTransferDetailsComponent,
    DateRangePickerComponent,
    FileInputComponent,
    FileViewerComponent
  ],
  bootstrap: [ConfirmationDialogComponent, AlertDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // 3rd party modules
    NgxStripeModule.forRoot(environment.stripePublicKey),
    TranslateModule,
    NgxSkeletonLoaderModule,

    // Material modules
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatExpansionModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,

    // Shared pipes
    CapitalizePipe,
    TruncatePipe,

    // Shared components
    GaugeChartComponent,
    UploadImageComponent,
    AddBoxComponent,
    GeneralConditionsComponent,
    CguComponent,
    TablePlaceholderComponent,
    LabelComponent,
    NavbarComponent,
    NotificationMenuComponent,
    DailyTransferDetailsComponent,
    DateRangePickerComponent,
    FileInputComponent,
    FileViewerComponent,

    // Material modules
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatStepperModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatExpansionModule,

    // 3rd party modules
    NgxStripeModule,
    TranslateModule,
    NgxSkeletonLoaderModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2000,
        horizontalPosition: 'end',
        verticalPosition: 'top'
      }
    },
    {
      provide: DateAdapter,
      useClass: IntlDateAdapter
    },
    {
      provide: MatPaginatorIntl,
      useClass: IntlPaginator
    }
  ]
})
export class SharedModule {}
