import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CompanySettings } from '@core/interfaces/company-settings';

import { User } from '@core/models/user';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  connectedUser$ = new BehaviorSubject(null);
  companySettings$ = new BehaviorSubject(null);
  sideBarSettings$ = new BehaviorSubject(false);

  constructor() {}

  updateConnectedUserSubj(user: User): void {
    this.connectedUser$.next(user);
  }

  updateCompanySettingsSubj(settings: CompanySettings): void {
    this.companySettings$.next(settings);
  }
  updateSideBar(status) {
    this.sideBarSettings$.next(status);
  }
}
