import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@buybutton/services';
import { ApiResponse } from '@core/interfaces/api-response';
import { GlobalErrorHandlerService } from '@core/services/global-error-handler/global-error-handler.service';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FidlyServiceInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private globalErrorHandlerService: GlobalErrorHandlerService) {}

  handleError(error: HttpErrorResponse) {
    if (error.status === 401 || (error.error.error && error.error.error.includes('authentication'))) {
      // unauthorize
      this._authService.signout().subscribe();
    }

    this.globalErrorHandlerService.handleError(error);
    throw error;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${this._authService.fidlyClientToken}`
      }
    });

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        // Case: 401 throwd from an endpoint other then refresh token endpoint
        if (errorResponse.status === 401 && !errorResponse.url.includes('/refresh-token')) {
          // Refresh the token
          return this._authService.refreshToken().pipe(
            switchMap((apiResponse: ApiResponse) => {
              // Retry the original request with the new token if successful
              if (apiResponse.success) {
                const clonedRequest = request.clone({
                  withCredentials: true,
                  setHeaders: {
                    Authorization: `Bearer ${apiResponse.data.token}`
                  }
                });
                return next.handle(clonedRequest);
              }
            })
          );
        }

        this.handleError(errorResponse);
      })
    );
  }
}
