<div class="fy-signup fy-public-page">
  <div class="fy-public-page__content">
    <div class="fy-signup__header">
      <div class="fy-signup__header__logo">
        <img src="assets/icons/lyzi.svg" alt="lyzi logo" />
      </div>
      <p class="fy-signup__header__title" translate>public.signup.title</p>
    </div>

    <div class="fy-form">
      <form class="fy-form__content">
        <!-- firstName field -->
        <mat-form-field>
          <mat-label translate>forms.firstName.label</mat-label>
          <input matInput [formControl]="firstName" type="text" autocomplete="given-name" />
          <mat-error *ngIf="firstName.errors?.required" translate>forms.firstName.errors.required</mat-error>
        </mat-form-field>

        <!-- lastName field -->
        <mat-form-field>
          <mat-label translate>forms.lastName.label</mat-label>
          <input matInput [formControl]="lastName" type="text" autocomplete="family-name" />
          <mat-error *ngIf="lastName.errors?.required" translate>forms.lastName.errors.required</mat-error>
        </mat-form-field>

        <!-- email field -->
        <mat-form-field>
          <mat-label translate>forms.email.label</mat-label>
          <input matInput [formControl]="email" type="email" autocomplete="email" />
          <mat-error *ngIf="email.errors?.required" translate>forms.email.errors.required</mat-error>
          <mat-error *ngIf="email.errors?.email" translate>forms.email.errors.email</mat-error>
          <mat-error *ngIf="email.errors?.exists" translate>forms.email.errors.exists</mat-error>
        </mat-form-field>

        <!-- companyName field -->
        <mat-form-field>
          <mat-label translate>forms.company.label</mat-label>
          <input matInput [formControl]="companyName" type="text" autocomplete="organization" />
          <mat-error *ngIf="companyName.errors?.required" translate>forms.company.errors.required</mat-error>
          <mat-error *ngIf="companyName.errors?.exists" translate>forms.company.errors.exists</mat-error>
        </mat-form-field>

        <!-- password field -->
        <mat-form-field>
          <mat-label translate>forms.password.label</mat-label>
          <input
            matInput
            [formControl]="password"
            [type]="passwordHidden ? 'password' : 'text'"
            autocomplete="new-password"
          />
          <mat-icon
            matSuffix
            color="primary"
            style="cursor: pointer"
            (click)="passwordHidden = !passwordHidden"
            [matTooltip]="'forms.password.tooltip.' + (passwordHidden ? 'show' : 'hide') | translate"
          >
            {{ passwordHidden ? 'visibility_off' : 'visibility' }}
          </mat-icon>
          <mat-error *ngIf="password.errors?.required" translate>forms.password.errors.required</mat-error>
        </mat-form-field>

        <!-- confirmPassword field -->
        <mat-form-field>
          <mat-label translate>forms.confirmPassword.label</mat-label>
          <input matInput [formControl]="confirmPassword" [type]="passwordHidden ? 'password' : 'text'" />
          <mat-error *ngIf="confirmPassword.errors?.required" translate>
            forms.confirmPassword.errors.required
          </mat-error>
          <mat-error *ngIf="confirmPassword.errors?.matches" translate>forms.confirmPassword.errors.matches</mat-error>
        </mat-form-field>

        <!-- acceptConditions field -->
        <div class="fy-signup__terms">
          <mat-checkbox class="fy-signup__terms__checkbox" color="accent" [formControl]="acceptConditions">
            <span translate>public.signup.accept-conditions.0</span>
            <a class="terms-txt" target="_blank" href="https://www.lyzi.io/cgv" translate>
              public.signup.accept-conditions.1
            </a>
          </mat-checkbox>
        </div>
        <!-- accept I am parent Company -->
        <div class="fy-signup__terms" style="display: none">
          <mat-checkbox class="fy-signup__terms__checkbox" color="accent" (click)="onClick()">
            <span translate>public.signup.parentCompanyExist</span>
          </mat-checkbox>
        </div>
      </form>
    </div>

    <div class="fy-signup__signup-box">
      <button mat-raised-button class="fy-button" [disabled]="!signUpForm.valid || isLoading" (click)="onSignUp()">
        <span *ngIf="!isLoading" translate>public.signup.submit</span>
        <span *ngIf="!isLoading">{{ischecked ? " SM" : ""}}</span>
        <mat-spinner *ngIf="isLoading" [strokeWidth]="2" [diameter]="30"></mat-spinner>
      </button>

      <div class="fy-signup__signup-box__signup">
        <span class="qst-text" translate>public.signup.already-have-an-account</span>&nbsp;
        <a class="terms-txt" routerLink="/signin" translate>public.signup.sign-in</a>
      </div>
    </div>
    <div class="fy-signup__footer">
      <span translate>public.signup.version</span>&nbsp;
      <span>{{ appVersion }}</span>
    </div>
  </div>
</div>
