<div class="fy-conditions">
  <div class="fy-conditions__header">
    <div class="fy-conditions__header__img">
      <img src="../../../../assets/icons/lyzi.svg" />
    </div>

    <div class="fy-conditions__header__txt">
      <span><strong>CONDITIONS GENERALES d’UTILISATION LYZI</strong> </span><br />
      <span>Valable au 01/01/2020</span>
    </div>
  </div>

  <div class="fy-conditions__content">
    <!-- PREAMBULE -->
    <section class="fy-section">
      <span class="fy-section__title">PREAMBULE</span>
      <p class="fy-section__para">
        Le Site et l’Application Application Lyzi sont édités par Proofeo SAS, société par actions simplifiée au capital
        social de 20..000 euros dont le siège social est situé 25, rue de la Libération – 92500 Rueil Rueil-malmaison
        immatriculée au RCS sous le numéro 848 571 337 et dont le numéro de TVA est le FR45848571337
      </p>

      <p class="fy-section__para">
        La Société Proofeo peut être contactée aux coordonnées suivantes :
        <br />
        - contact@proofeo.io
      </p>

      <p class="fy-section__para">Le Directeur de publication est Louis-marie Borione</p>

      <p class="fy-section__para">
        Le Site est hébergé par la société OVH :
        <br />
        - SAS au capital de 10 059 500 euros <br />
        - RCS Lille Métropole 424 761 419 00045 <br />
        - N° TVA : FR 22 424 761 419 <br />
        - Adresse postale : 2 rue Kellermann - 59100 Roubaix - France. <br />
        - Téléphone : 09 72 10 10 07
      </p>

      <p class="fy-section__para">
        L’intégralité du Site et de l’Application est la propriété exclusive de la Société Proofeo. Toute reproduction,
        représentation, altération, partielle ou totale de l’Application ou d’un de ses éléments est strictement
        interdite.
      </p>
    </section>

    <!-- Article 1 – Définitions -->
    <section class="fy-section">
      <span class="fy-section__title">Article 1 – Définitions</span>
      <p class="fy-section__para">
        <span class="txt-underline">Site :</span> désigne l’application mobile par laquelle Proofeo fournit l’accès à
        ses partenaires à la solution Lyzi . La solution consiste en la génération de point de fidélités dématérialisées
        auprès de ces partenaires. Les utilisateurs peuvent changer c ou donner leurs points fidélités fidélités.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">La solution :</span> La solution consiste en la génération de point de fidélités
        dématérialisées auprès de partenaires/ commerçants. Les utilisateurs peuvent changer hanger ou donner leurs
        points fidélités.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Application :</span> désigne l’application mobile Lyzi par laquelle Proofeo fournit
        la possibilité aux utilisateurs de gérer, générer et utiliser des points de fidélités auprès des commerçants
        partenaires.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Lyzi :</span> Nom de l’application au service de la solution Proofeo.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Génération de points :</span> désigne toute demande de génération de points de
        fidélités par un partenaire pour un utilisateur via le site ou l’application.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Compte Utilisateur :</span> désigne l’espace créé lors de l’inscription à l’aide
        d’un formulairepermettant par la suite à l’Utilisateur d’accéder à son Espace Personnel.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Conditions Générales :</span> L’ensemble des présentes dispositions ayant force
        obligatoire pour les Utilisateurs, les partenaires et la Société.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Proofeo:</span> désigne Proofeo SAS, une société par actions simplifiée au capital
        de20.000 euros, dont le siège social est situé 25, rue de la Libération – 92500 Rueil-Malmaison, immatriculée au
        RCS sous le numéro 848 571 337.
      </p>

      <p class="fy-section__para">Proofeo désigne également le nom commercial de l’application et du site.</p>

      <p class="fy-section__para">
        Partenaire (s) désigne le ou les commerçants répertoriés sur la Plateforme Lyzi et ayant souscris à la solution
        Proofeo.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Espace Personnel :</span> désigne un espace qui permet à l’Utilisateur de gérer son
        utilisation desServices et des point de fidélité. L’Utilisateur peut accéder à tout moment à son Espace
        Personnel après s’être identifié à l’aide de son identifiant de connexion ainsi que de son motde passe.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Point de fidélité:</span> désigne des points accumulés pour l’achat auprès des
        partenaires et pouvant amener par la suite des avantages. Ces points de fidélité peuvent être transmis ou
        transformé en crypto monnaie ou monnaie fiduciaire.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">La souscription :</span> Le fait pour un partenaire d’adhérer à la solution Lyzi
        permettant la génération, la gestion et la transformation de points de fidélité.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Plateforme Proofeo:</span> désigne l'ensemble des logiciels d'application, logiciels
        deconnexion, bases de données, méthodes, procédés ou tout autre élément dont les droits depropriété
        intellectuelle sont détenus par la société Proofeo et qui sont nécessaires aufonctionnement du Site, de
        l’Application et à la fourniture du Service.
      </p>

      <p class="fy-section__para">
        Service(s) : désigne la prestation de création et de gestion des points de fidélité.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Site :</span> désigne le nom de domaine et sous-nom de domaine www.fidly.io le site
        Internet et l’infrastructure développés pour Proofeo selon les formats informatiques utilisables sur l’Internet
        comprenant des données de différentes natures et, notamment, destextes, sons, images fixes ou animées, vidéos,
        bases de données destinées à être consultées parl’Utilisateur pour connaître ses Services.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">STRIPE :</span> désigne le système de paiement choisi pour le bon fonctionnement de
        la solution web.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">Utilisateur(s) :</span> désigne tout utilisateur personne physique situé en France
        ou à l’étranger valablement enregistré auprès de Proofeo qui navigue, prend connaissance, Commandedes points de
        fidélités par l’intermédiaire du Service proposé par la solution Proofeo sur le Site ou l’Application ou par
        tout autre canal de mise en relation proposé.
      </p>

      <p class="fy-section__para">
        <span class="txt-underline">FID :</span> désigne la propre crypto-monnaie de la solution Proofeoqui permet de
        connecter n'importe quel programme fidélité à la solution.
      </p>
    </section>

    <!-- Article 2 – Objet -->
    <section class="fy-section">
      <span class="fy-section__title">Article 2 – Objet</span>
      <p class="fy-section__para">
        Les Conditions Générales ont pour objet de déterminer les conditions juridiques, techniques et financières des
        relations existant entre les utilisateurs, les partenaires et la société Proofeo aux termes duquel :
      </p>

      <p class="fy-section__para">
        <br />
        - Lyzi référence les partenaires sur l’application suite à la souscription de la solution par ceux-ci sur le
        Site; <br />
        - Les partenaires proposent la possibilité de gagner, gérer et utiliser (transmission et transformation) des
        points de fidélité en respectant l’image de marque de la plateforme.
      </p>
    </section>

    <!-- Article 3 – Généralités -->
    <section class="fy-section">
      <span class="fy-section__title">Article 3 – Généralités</span>
      <div class="fy-section__sub-title">
        <span>3.1 Disponibilité</span>
      </div>

      <p class="fy-section__para">
        Les Conditions Générales sont mises à la disposition des utilisateurs et des partenaires sur le Site/
        l’Application de la société Proofeo où elles sont directement consultables.
      </p>

      <div class="fy-section__sub-title">
        <span>3.2 Intégralité et Opposabilité</span>
      </div>

      <p class="fy-section__para">
        La validation de l’inscription au Site et/ou à l’Application vaut adhésion par les utilisateurs et les
        partenaires aux Conditions Générales en vigueur au jour de l’inscription, dès lors qu’ils déclarent expressément
        les accepter en cochant une case prévue à cet effet. En ce sens, les utilisateurs et les partenaires acceptent
        sans réserve l'intégralité des stipulations prévues dans lesdites conditions.
      </p>

      <p class="fy-section__para">
        Ces dernières ont force obligatoire pendant toute la durée de la souscription et expriment l'intégralité des
        obligations et des droits des partenaires. Les informations figurant sur tout autre document, quel qu’il soit,
        n’ayant qu’une valeur indicative.
      </p>

      <p class="fy-section__para">
        Les présentes Conditions sont rédigées ainsi que l'ensemble des informations mentionnées sur le Site en langue
        française.
      </p>

      <div class="fy-section__sub-title">
        <span>3.3 Modifications</span>
      </div>

      <p class="fy-section__para">Proofeo se réserve la faculté de modifier ses CGU à tout moment.</p>

      <p class="fy-section__para">
        En cas de modification de celles-ci, les Conditions applicables sont celles en vigueur à la date de la
        réservation dont une copie datée à ce jour peut être remise à la demande des Partenaires et utilisateurs.
      </p>
    </section>

    <!-- Article 4 – Accès aux Services -->
    <section class="fy-section">
      <span class="fy-section__title">Article 4 – Accès aux Services</span>
      <div class="fy-section__sub-title">
        <span>4.1 Acceptation des Conditions Générales</span>
      </div>

      <p class="fy-section__para">
        Toute connexion à la Plateforme est subordonnée au respect des Conditions Générales accessibles directement sur
        le site www.fidly.io et sur l’Application.
      </p>

      <p class="fy-section__para">
        Le fait de cocher la case : « J’ai pris connaissance et accepte les conditions générales d’utilisation » avant
        tout inscription à l’Application et/au Site constitue une acceptation pleine, entière et éclairée des Conditions
        Généralesque les partenaires et utilisateurs reconnaissent avoir lues, comprises et acceptées sans exception
        aucune.
      </p>

      <div class="fy-section__sub-title">
        <span>4.2 Exclusivement via Internet</span>
      </div>

      <p class="fy-section__para">
        Les partenaires et les Utilisateurs sont informés du fait que l’exécution des Conditions Générales est
        subordonnée à son inscription effective sur la Plateforme en ligne Lyzi accessible à l’URL suivant :
        www.fidly.io ou directement sur l’Application.
      </p>

      <p class="fy-section__para">
        Toutefois, Proofeo se réserve le droit de suspendre temporairement l'accès à l’Application et/ou au Site pour
        des raisons techniques ou de maintenance sans que ces opérations n'ouvrent droit à une quelconque indemnisation.
        Proofeo s'engage toutefois à limiter ce type d'interruption au strict nécessaire.
      </p>

      <div class="fy-section__sub-title">
        <span>4.3 Téléchargement de l’Application</span>
      </div>

      <p class="fy-section__para">
        L’Application est utilisable sur téléphones et tablette mobiles, dans les systèmes d’exploitation IOS et
        Android.
      </p>

      <p class="fy-section__para">
        Les Utilisateurs et les partenaires qui souhaitent utiliser les Services doivent télécharger l’Application
        depuis la plateforme de téléchargement d’applications appropriée. Ces téléchargements sont soumis aux conditions
        d’utilisation propres à ladite plateforme.
      </p>

      <div class="fy-section__sub-title">
        <span>4.4 Capacité des Partenaires et des Utilisateurs</span>
      </div>

      <p class="fy-section__para">
        L’Application et les Services sont accessibles à toutes personnes physiques ou morales disposant de la pleine
        capacité juridique pour s’engager au titre des présentes Conditions Générales.
      </p>

      <p class="fy-section__para">
        Conformément aux dispositions légales et réglementaires en vigueur, les Services Partenaires sont uniquement
        accessibles aux personnes juridiquement capables de s’engager au titre des Conditions Générales.
      </p>

      <p class="fy-section__para">
        Les Partenaires s’engagent à respecter toutes les obligations légales et réglementaires et à effectuer toutes
        les formalités notamment administratives, fiscales et sociales qui leur incombent le cas échéant du fait de
        l’utilisation des Services et/ou dans le cadre des services qu’ils proposent
      </p>
    </section>

    <!-- Article 5 – Service de référencement Proofeo -->
    <section class="fy-section">
      <span class="fy-section__title">Article 5 – Service de référencement Proofeo</span>
      <div class="fy-section__sub-title">
        <span>5.1 Mise en place de la fidélité Client</span>
      </div>

      <p class="fy-section__para">
        La Plateforme est destinée à la mise en relation d’Utilisateur et de Partenaires commerçants dans le cadre d’un
        service de génération et de gestion de points de fidélité dématérialisé pour lequel le Partenaire réalise en son
        nom et pour son compte la comptabilisation de points de fidélité acquis par l’Utilisateur.
      </p>

      <p class="fy-section__para">
        Elle met ainsi à la disposition des Partenaires des outils et moyens techniques leur permettant de mettre en
        ligne leur profil personnalisé afin de proposer leurs services aux Utilisateurs.
      </p>

      <div class="fy-section__sub-title">
        <span>5.2 Principe général d’indépendance des Partenaires</span>
      </div>

      <p class="fy-section__para">
        Proofeo permet uniquement la mise en relation de ces acteurs en tant qu’intermédiaire et solution technique
        entre les Utilisateurs et les Partenaire.
      </p>

      <p class="fy-section__para">
        Proofeo n’intervient aucunement dans la relation Utilisateur/ Partenaires, en ce qu’il n’est ni l’employeur, ni
        le mandataire des Utilisateurs et des partenaires de sa Plateforme.
      </p>

      <p class="fy-section__para">
        Ainsi, la responsabilité de l’éditeur du Site et de l’Application se limite à la fourniture de moyens décrite
        aux Conditions Générales.
      </p>

      <p class="fy-section__para">
        Il n’existe aucun lien de subordination entre Proofeo et les Partenaires dans la mesure où ils ont seuls la
        faculté de proposer leurs services aux Utilisateurs et qu’ils sont responsables de leur activité et de la
        manière dont ils attribuent leur point de fidélité.
      </p>

      <p class="fy-section__para">
        La prestation fournie par le Partenaire est ainsi régie par le contrat devant être conclu avec l’Utilisateur.
      </p>

      <p class="fy-section__para">
        Proofeo ne pourra voir sa responsabilité engagée au titre d’un quelconque dommage intervenu lors de l’exécution
        ou de l’inexécution des services ou de la génération de points de fidélité. Proofeo n’ayant aucunement
        l’obligation de vérifier la qualité et les conditions dans lesquelles se déroulent les services sur sa
        Plateforme.
      </p>

      <p class="fy-section__para">
        De même, les Partenairess’engagent seuls à respecter toutes les obligations légales et réglementaires et à
        effectuer toutes les formalités notamment administratives, fiscales et sociales qui leur incombent le cas
        échéant du fait de l’utilisation des services et/ou dans le cadre de l’activité qu’ils proposent.
      </p>

      <div class="fy-section__sub-title">
        <span>5.3 En complément de leur clientèle personnelle</span>
      </div>

      <p class="fy-section__para">
        En accédant à la Plateforme Proofeo, les Partenaires s’engagent à céder les informations de leurs clients
        utilisateurs de la solution dans le seul but de la bonne marche de la solution auquel le partenaire a souscrit.
      </p>

      <p class="fy-section__para">
        A cet effet, il est formellement stipulé que cette clientèle devra s’ajouter à la clientèle des autres
        partenaires sans aucunement que des informations personnelles soient transmises.
      </p>
    </section>

    <!-- Article 6 – Processus de Commande -->
    <section class="fy-section">
      <span class="fy-section__title">Article 6 – Processus de Commande</span>
      <div class="fy-section__sub-title">
        <span>6.1 Création du Compte Utilisateur / ou du Compte Partenaire</span>
      </div>

      <p class="fy-section__para">
        L’utilisation des Services nécessite que les Utilisateurs et les Partenaires s’inscrivent en remplissant le
        formulaire prévu à cet effet.
      </p>

      <p class="fy-section__para">
        Les Utilisateurs doivent :
        <br />
        o renseigner une adresse email et déterminer un mot de passe <br />
        o remplir le formulaire d’inscription disponible sur l’Application ;
      </p>

      <p class="fy-section__para">
        Les Partenaires doivent :
        <br />
        o renseigner une adresse email et déterminer un mot de passe <br />
        o remplir le formulaire d’inscription disponible sur la plateforme
      </p>

      <p class="fy-section__para">
        Dans tous les cas, les Utilisateurs et les Partenaires doivent fournir l’ensemble des informations marquées
        comme obligatoires. Toute inscription incomplète ne sera pas validée.
      </p>

      <div class="fy-section__sub-title">
        <span>6.2 Espace Personnel</span>
      </div>

      <p class="fy-section__para">
        Le Compte Utilisateur/Partenaires permet à leur titulaire d’avoir accès à leur Espace Personnel.
      </p>

      <p class="fy-section__para">
        Les Utilisateurs et les Partenaires garantissent que toutes les informations communiquées sont exactes, à jour
        et sincères et ne sont entachées d’aucun caractère trompeur.
      </p>

      <p class="fy-section__para">
        Ils s’engagent à mettre à jour ces informations dans leur Espace Personnel en cas de modifications, afin
        qu’elles correspondent toujours aux critères susvisés.
      </p>

      <p class="fy-section__para">
        Les Utilisateurs et les Partenaires sont responsables du maintien de la confidentialité de leur identifiant et
        mot de passe.
      </p>

      <div class="fy-section__sub-title">
        <span>6.3 Connexion de l’Utilisateur</span>
      </div>

      <p class="fy-section__para">
        L’Utilisateur doit tout d’abord se connecter à l’Application en renseignant au besoin son identifiant et son mot
        de passe.
      </p>

      <p class="fy-section__para">
        Celui-ci devra sélectionner l’Offre du partenaire qu’il souhaite voir réaliser. Il sera alors libre d’accepter
        ou de refuser la Commande émise par l’Utilisateur dans un délai de 10 secondes.
      </p>
    </section>

    <!-- Article 7 – Réalisation du service -->
    <section class="fy-section">
      <span class="fy-section__title">Article 7 – Réalisation du service</span>
      <p class="fy-section__para">
        Le Partenaire peut générer des points de fidélité à travers la solution pour des utilisateurs venant de sa
        propre clientèle ou apporter un service sur la plateforme pour des utilisateurs de l’application Lyzi.
      </p>

      <p class="fy-section__para">
        Les utilisateurs peuvent alors à travers la solution cumuler des points fidélité sur plusieurs programmes
        amenant à récompenses.
      </p>

      <p class="fy-section__para">
        L’utilisateur pourra changer ou céder les points fidélités avant qu’ils n’arrivent à expiration en les
        transmettant à leurs amis, famille, ou en les transformant en crypto monnaie ou monnaie fiduciaire.
      </p>

      <p class="fy-section__para">Il devra au fur et à mesure valider sur l’Application chaque étape.</p>
    </section>

    <!-- Article 8 – Résolution de la souscription ou du compte personnel -->
    <section class="fy-section">
      <span class="fy-section__title">Article 8 – Résolution de la souscription ou du compte personnel</span>
      <div class="fy-section__sub-title">
        <span>8.1 Par l’Utilisateur</span>
      </div>

      <p class="fy-section__para">
        L’utilisateur peut choisir de supprimer son compte. Il s’engage alors à perdre ses points de fidélités qui sont
        rattachés au compte lui-même. Ces points retombent dans la blockchain.
      </p>

      <p class="fy-section__para">
        Dans le cas où le compte d’un utilisateur est dit « en sommeil » soit une inactivité de compte durant une durée
        d’un an, le compte sera mis en attente. L’utilisateur pourra alors rouvrir son compte. Les points étant
        rattachés au compte, l’utilisateur les retrouvera à la réouverture.
      </p>

      <div class="fy-section__sub-title">
        <span>8.2 Par le Partenaire</span>
      </div>

      <p class="fy-section__para">
        Le Partenaire peut résoudre sa souscription auprès de Lyzi en envoyant un email à l’adresse contact@proofeo.io.
        La société Proofeo, ne remboursera pas toutefois les sommes engagées par le partenaire lors de la souscription à
        la solution.
      </p>
    </section>

    <!-- Article 9– Paiement -->
    <section class="fy-section">
      <span class="fy-section__title">Article 9– Paiement</span>
      <div class="fy-section__sub-title">
        <span>9.1 via le prestataire Stripe</span>
      </div>

      <p class="fy-section__para">
        En vue du bon fonctionnement de son Site, de son Application et de la sécurisation des paiements effectués par
        les Utilisateurs, Proofeo a décidé de faire appel à un tiers de confiance pour procéder à l’encaissement des
        sommes mise en paiement : STRIPE.
      </p>

      <p class="fy-section__para">
        A ce titre, les partenaires ayant souscris à la solution déclarent avoir pris connaissance des Conditions
        Générales d’Utilisation de STRIPE 7(https://stripe.com/connect/account-terms) et les accepter sans réserve en
        s’inscrivant sur la plateforme à la solution.
      </p>

      <div class="fy-section__sub-title">
        <span>9.2 Frais de la plateforme STRIPE</span>
      </div>

      <p class="fy-section__para">
        Ce tiers de confiance STRIPE a pour modèle économique le prélèvement d’un forfait par vente effectuée.
      </p>

      <p class="fy-section__para">
        Le taux de commission au sein de l'Union Européenne est de 1,4% + 0,25€ pour les cartes européennes et de 2,9% +
        0,25€ pour les cartes non-européennes.
      </p>

      <p class="fy-section__para">Cette commission sera supportée par les Partenaires qui l’acceptent.</p>

      <div class="fy-section__sub-title">
        <span>9.3 Moyens de paiement</span>
      </div>

      <p class="fy-section__para">
        Le paiement de souscription à la solution s'effectue immédiatement et intégralement et ce uniquement en ligne
        par carte bancaire (Visa, Master Card, CB, AmEx), dès le début de la collaboration.
      </p>

      <div class="fy-section__sub-title">
        <span>9.4 Sécurisation des données bancaires</span>
      </div>

      <p class="fy-section__para">
        Pour que lespartenaires puissent avoir recours en toute tranquillité aux Services Proofeo, STRIPE est doté d'un
        système de sécurisation des paiements en ligne permettant au consommateur de crypter la transmission de ses
        données bancaires.
      </p>

      <p class="fy-section__para">
        Proofeo ne saurait toutefois être responsable de la dispersion et de la divulgation de ces données indépendantes
        de sa volonté. Les partenaires ne pouvant en pareil cas, engager la responsabilité de Proofeo ou l’appeler en
        garantie.
      </p>
    </section>

    <!-- Article 10 – Facture -->
    <section class="fy-section">
      <span class="fy-section__title">Article 10 – Facture</span>
      <div class="fy-section__sub-title">
        <span>10.1 Emission</span>
      </div>

      <p class="fy-section__para">
        Une fois la solution souscrite, Proofeo adresse au partenaire, par courrier électronique, une facture qui
        détaille le prix.
      </p>

      <div class="fy-section__sub-title">
        <span>10.2 Contestation</span>
      </div>

      <p class="fy-section__para">
        Le partenaire dispose d’un délai de 30 jours à compter de la date de réception de la facturepour former une
        contestation écrite. A l’expiration de ce délai, la facture et notamment lemontant y afférent seront réputés
        acceptés. Une contestation ne peut en aucun cas justifier unretard dans le règlement de la totalité de la
        facture.
      </p>

      <div class="fy-section__sub-title">
        <span>10.3 Défaut de paiement</span>
      </div>

      <p class="fy-section__para">
        En cas de retard de paiement ou pour tout moyen de règlement revenu impayé pour Tout manquement de l'une ou
        l'autre des parties aux obligations qu'elle a en charge, entraînera, si bon semble au créancier de l'obligation
        inexécutée, la résiliation de plein droit au présent contrat, quinze jours après mise en demeure d'exécuter par
        lettre 8recommandée avec accusé de réception ou email demeurée sans effet, sans préjudice de tous dommages et
        intérêts. Cela n'entraînera toutefois pas le remboursement de la solution auprès du partenaire.
      </p>
    </section>

    <!-- Article 11– Obligations des Partenaires -->
    <section class="fy-section">
      <span class="fy-section__title">Article 11– Obligations des Partenaires</span>
      <p class="fy-section__para">
        Pour bénéficier des avantages de la solution, les partenaires devront se conformer à certaines obligations
        nécessaires au bon fonctionnement du Site et/ou de l’Application et à l’image Proofeo.
      </p>

      <p class="fy-section__para">
        Les partenaires s’engagent à l’égard des Utilisateurs à :
        <br />
        o Respecter la prestation contractée <br />
        o Créditer les points fidélité correspondant à l’achat effectué <br />
        o Faire bénéficier à l’utilisateur de l’offre sélectionnée via l’application Lyzi
      </p>

      <p class="fy-section__para">
        De plus, les partenaires s’engagent à l’égard de Proofeo à :
        <br />
        o Utiliser le Site et l’Application conformément aux présentes CGU, comme énoncé en amont. <br />
        o Ne jamais utiliser le Site et l’Application en portant atteinte aux droits des tiers et plus généralement à
        l’ordre public, aux bonnes mœurs et à toutes dispositions légales ou réglementaires en vigueur ; <br />
        o Respecter l’image de marque de Proofeo <br />
        o Etre titulaire de tout habilitation nécessaire à l’exercice de son activité ; <br />
        o Ne jamais mettre dans la cause Proofeoen cas de poursuites judiciaires exercées à son encontre ; <br />
        o Garantir Proofeo en cas d’action en responsabilité civile directement engagée à son encontre ; <br />
        o Mettre à jour son profil régulièrement sans enfreindre les droits des tiers ;
      </p>
    </section>

    <!-- Article 12 – Cas d’exclusion de la solution. -->
    <section class="fy-section">
      <span class="fy-section__title">Article 12 – Cas d’exclusion de la solution</span>
      <div class="fy-section__sub-title">
        <span>12-1 Informations frauduleuses ou erronées</span>
      </div>

      <p class="fy-section__para">
        Proofeo se réserve le droit d’exclure du Site/Application, sans délai ni justification, tout membre ayant
        délivré lors de son inscription et pendant toute la durée d’utilisation, des informations erronées ou
        frauduleuses.
      </p>

      <p class="fy-section__para">
        Dans pareille situation, Proofeo s’engage également à mener à l’encontre des usurpateurs toutes mesures
        judiciaires permettant de sanctionner ces comportements déviants.
      </p>

      <div class="fy-section__sub-title">
        <span>12.2 Erreurs et réclamations</span>
      </div>

      <p class="fy-section__para">
        Aux fins de bon fonctionnement de la Plateforme, est mis à la disposition des Utilisateurs et des Partenaires un
        service réclamation leur permettant de relater tout mécontentement ou incident intervenu lors de la génération
        et de la gestion des points de fidélité ou des FID.
      </p>

      <p class="fy-section__para">
        Les utilisateurs peuvent ainsi adresser par courrier électronique à l’adresse email contact@proofeo.io ou par
        lettre recommandée avec demande d’avis de réception toute réclamation relative à un incident auprès de Proofeo.
      </p>

      <p class="fy-section__para">
        Ainsi, une enquête sera alors ouverte à la discrétion de Proofeo afin de vérifier la possibilité d’une erreur.
      </p>

      <div class="fy-section__sub-title">
        <span>12.3 Non-conformité aux exigences légales et réglementaires</span>
      </div>

      <p class="fy-section__para">
        Après adhésion au Site/Application, il se peut que la situation administrative du partenaire change. Dans le cas
        où ces changements sont contraires aux conditions d’admission et/ou d’utilisation, le partenaire s’engage à
        cesser de recourir aux Services Proofeo et à informer la société dans les plus brefs délai de son changement de
        situation aux fins de procéder à son exclusion de la Plateforme.
      </p>

      <p class="fy-section__para">
        Si un tel changement n’est pas spontanément déclaré à Proofeo, celle-ci dégage toute responsabilité en cas de
        survenance d’un quelconque dommage et se réserve le droit d’agir en justice à l’encontre de tout partenaire
        contrevenant à ces Conditions Générales en réparation du préjudice subi.
      </p>

      <div class="fy-section__sub-title">
        <span>12.4 Compte inactif</span>
      </div>

      <p class="fy-section__para">
        Proofeo se réserve le droit de mettre en attente les Comptes demeurés inactifs pendant un an qu’elle jugera trop
        long à son entière discrétion et préjudiciable au bon fonctionnement de sa plateforme.
      </p>
    </section>

    <!-- Article 13 – Responsabilité et garantie -->
    <section class="fy-section">
      <span class="fy-section__title">Article 13 – Responsabilité et garantie</span>
      <p class="fy-section__para">
        Proofeo assure une simple mise à disposition d’une solution technique de création, de gestion et d’hébergement
        de FID. A ce titre, il n’intervient aucunement dans la relation contractuelle existant entre les membres de son
        Site/Application soit entre les utilisateurs et partenaires.
      </p>

      <p class="fy-section__para">
        Ceux-ci n’engagent que leurs seules et uniques responsabilités à l’exception de celle de Proofeo n’agissant
        qu’en qualité de simple intermédiaire. Le partenaire demeure seul responsable de l’exécution conforme de la
        prestation vis-à-vis de l’Utilisateur.
      </p>

      <p class="fy-section__para">
        Ainsi, la responsabilité de l’éditeur du Site et de l’Application se limite à la fourniture de moyens décrite
        aux présentes CGU.
      </p>

      <p class="fy-section__para">
        Proofeo ne pourra voir sa responsabilité engagée au titre d’un quelconque dommage intervenu lors de l’exécution
        ou de l’inexécution d’un service. Proofeo n’ayant aucunement l’obligation de vérifier la qualité et les
        conditions dans lesquelles se déroulent les services ou les avantages.
      </p>

      <p class="fy-section__para">
        De même, les partenaires s’engagent seuls à respecter toutes les obligations légales et réglementaires et à
        effectuer toutes les formalités notamment administratives, fiscales 10et sociales qui leur incombent le cas
        échéant du fait de l’utilisation des Services et/ou dans le cadre de l’activité qu’ils proposent, notamment en
        terme d’assurance de responsabilité civile couvrant notamment les dommages aux tiers pouvant résulter de leur
        activité.
      </p>

      <p class="fy-section__para">
        Bien que Proofeo s'engage à tout mettre en œuvre pour assurer la permanence, la continuité et la qualité de son
        Service, sa responsabilité ne saurait toutefois être engagée pour tous les inconvénients ou dommages inhérents à
        l’utilisation du réseau Internet ; notamment une rupture de service, une intrusion extérieure ou la présence de
        virus informatiques, de sorte que Proofeo souscrit à ce titre à une obligation de moyens.
      </p>

      <p class="fy-section__para">
        Le partenaire déclare accepter les caractéristiques et les limites de l'Internet et en particulier reconnaît :
        <br />
        - que Proofeo ne peut en aucune manière être tenue responsable notamment des difficultés ou des vitesses et des
        ralentissements d'accès au Site et à l’Application, du non-acheminement des informations ou de la non-validation
        d’une Commande pour des raisons techniques, <br />
        - avoir connaissance de la nature du réseau Internet et en particulier de ses performances techniques et des
        temps de réponse pour consulter, interroger ou transférer des informations ou contenus, <br />
        - qu'il lui appartient de prendre toutes les mesures appropriées de nature à protéger ses propres données et/ ou
        logiciels et/ ou matériels de la contamination par des éventuels virus informatiques ou tentatives d'intrusion
        ou tout autre logiciel et programme susceptible de nuire au fonctionnement ou d'endommager un matériel
        informatique.
      </p>

      <p class="fy-section__para">
        Le Site et l’Application Proofeo peuvent contenir des liens vers d'autres sites partenaires. Proofeo ne peut
        réaliser aucun contrôle sur ces sites et décline toute responsabilité en ce qui concerne leur contenu et
        services. Elle s’engage toutefois à rendre ineffectifs ces liens, si le contenu des sites référencés porte
        atteinte aux droits d’un tiers.
      </p>

      <p class="fy-section__para">
        De manière générale, Proofeo décline toute responsabilité en cas de litige intervenant entre les Utilisateurs et
        les partenaires
      </p>

      <p class="fy-section__para">
        En tout état de cause, il appartient au Membre d’apporter la preuve de la faute de Proofeo pour engager sa
        responsabilité.
      </p>
    </section>

    <!-- Article 14 – Force Majeure -->
    <section class="fy-section">
      <span class="fy-section__title">Article 14 – Force Majeure</span>
      <p class="fy-section__para">
        Sont réputés événements de force majeure tout événement ou circonstance imprévisible, irrésistible et extérieur
        rendant impossible l’exécution des CGU par Proofeo ou par le partenairetel que défini par la législation et la
        jurisprudence française.
      </p>

      <p class="fy-section__para">
        La partie touchée par de telles circonstances doit en aviser par tout moyen l’autre à bref délai à compter de la
        survenance de l’événement.
      </p>

      <p class="fy-section__para">
        Tous les événements de force majeure constituent une cause de suspension ou d'extinction des obligations de
        Proofeo et du partenaire. Ainsi, ceux-ci ne sauraient être tenus responsables de l'inexécution des présentes
        CGU.
      </p>
    </section>

    <!-- Article 15 – Propriété intellectuelle -->
    <section class="fy-section">
      <span class="fy-section__title">Article 15 – Propriété intellectuelle</span>
      <p class="fy-section__para">
        Les éléments reproduits sur le Site et l’Application Lyzi et notamment les photographies, visuels, textes,
        dessins, images, logos sont protégés de manière générale par le droit de la propriété intellectuelle et
        appartiennent à Proofeo.
      </p>

      <p class="fy-section__para">
        Proofeo conserve la propriété intellectuelle de l’ensemble de ces éléments ainsi que toutes les prérogatives s’y
        rattachant. Toute exploitation par le partenaireou l’Utilisateur est subordonnée à l’accord préalable et écrit
        de Proofeo.
      </p>

      <p class="fy-section__para">
        Ainsi, toute reproduction, représentation, modification, traduction, création de produits dérivés et de manière
        générale toute exploitation expose les contrevenants à des poursuites judiciaires.
      </p>

      <p class="fy-section__para">
        Par ailleurs, le partenaire et l’Utilisateur garantissent Proofeo de la propriété des droits afférents à
        l’exploitation de ces photographies et images du fait de leur création ou de la cession des différents droits de
        propriété intellectuelle afférents à son Espace Personnel.
      </p>

      <p class="fy-section__para">
        Ainsi, Proofeo ne saurait aucunement être reconnu responsable ou contrefacteur des contenus mis en ligne par les
        Partenaires.
      </p>

      <p class="fy-section__para">
        A ce titre, les partenaires et les Utilisateur s’autorisent expressément Proofeo à représenter, reproduire et de
        manière générale exploiter son image ou tout attrait de sa personnalité gratuitement, pour les besoins du Site
        et/ou de l’Application et ce pendant toute la durée des droits.
      </p>
    </section>

    <!-- Article 16– Données personnelles -->
    <section class="fy-section">
      <span class="fy-section__title">Article 16– Données personnelles</span>
      <p class="fy-section__para">
        Proofeo est soucieuse du respect des dispositions légales et réglementaires en matièrede vie privée et de
        protection des informations personnelles. Les informations renseignées sur le Site ne sont utilisées que pour le
        traitement du service de l’Utilisateur ainsi que pour renforceret personnaliser la communication et l'offre de
        Services, notamment par des lettres d'informations.
      </p>

      <p class="fy-section__para">
        Les données nominatives collectées et ultérieurement traitées par la société sont celles que l’utilisateur
        transmet volontairement au site notamment via l’inscription.
      </p>

      <p class="fy-section__para">
        Les données collectées, traitées et conservées sont d'une part, les données, qui sont transmises volontairement
        par l’utilisateur lors de son inscription ou ultérieurement dans le cadre de mises à jour des informations le
        concernant et d'autre part, les données collectées automatiquement par le site par le biais de Cookies.
      </p>

      <p class="fy-section__para">Les données personnelles sont stockées chez l'hébergeur du site internet.</p>

      <p class="fy-section__para">
        Les données sont conservées pour la durée de un an ou toute durée légale ou réglementaire maximale et impérative
        qui pourrait être substituée à la durée précitée. Au-delà des périodes mentionnées ci-dessus, les données et les
        données collectées automatiquement par le site seront conservées sous forme anonyme, à des fins exclusivement
        statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit
      </p>

      <p class="fy-section__para">
        La société s’engage à prendre des précautions techniques reconnues afin de préserver la sécurité des données à
        caractère personnel et notamment empêcher qu'elles ne soient déformées, endommagées ou communiquées à des tiers
        non autorisés.
      </p>

      <p class="fy-section__para">
        Néanmoins chaque visiteur du site reconnaît qu’en l’état de la technique actuelle, chaque fois qu’il fournit des
        informations personnelles en ligne, ces informations peuvent être collectées et utilisées par des tiers.
      </p>

      <p class="fy-section__para">
        Dès lors, l’utilisateur décharge la société de toute responsabilité ou conséquence dommageable de l’utilisation
        par des tiers des informations échangées par le biais des outils proposés par l’Application.
      </p>

      <p class="fy-section__para">
        Le Site et l’application Proofeo sont conformes aux législations françaises et européennes relatives à la
        protection de la vie privée et des données personnelles en vigueur, et la normesimplifiée 48 <br />
        - Délibération n° 2012-209 du 21 juin 2012 portant création d’une normesimplifiée concernant les traitements
        automatisés de données à caractère personnel relatifs à lagestion des Utilisateurs et de prospects.
      </p>

      <p class="fy-section__para">
        Conformément à la loi Informatique et Libertés du 6 janvier 1978 modifiée par la loi du 6 août 2004 relative à
        la protection des personnes physiques à l'égard des traitements desdonnées à caractère personnel, tout
        Utilisateur dispose, à tout moment, d'un droit d'accès, demodification, de rectification ou de suppression des
        données qui le concernent. Pour cela, il pourra consulter et modifier ses données de la façon suivante :
        <br />
        o Par courriel à l'adresse électronique suivante : contact@proofeo.io <br />
        o Par courrier à l’adresse postale suivante : 25 rue de la Libération 92500 Rueil- Malmaison
      </p>
    </section>

    <!-- Article 17– Cookies -->
    <section class="fy-section">
      <span class="fy-section__title">Article 17– Cookies</span>
      <p class="fy-section__para">
        Le Site et l’Application peuvent collecter des informations de manière automatique par l'utilisation de «
        cookies » (ou témoins de connexion) qui permettent notamment de suivre lanavigation des Utilisateurs et
        d’établir des statistiques anonymes sur la fréquentation et la navigation sur le Site
      </p>

      <p class="fy-section__para">
        Il enregistre des informations relatives à la navigation afin d'établir les paramètresde la session durant la
        connexion. Ces cookies permettent à Proofeo de traiter efficacement les demandes et d'assurer le bon
        fonctionnement du Site et de l’Application.
      </p>

      <p class="fy-section__para">
        Les cookies sont notamment utiliser pour mémoriser les coordonnées de l’Utilisateur / du partenaire lors deson
        identification afin qu’il n'ait plus à les fournir par la suite (cette mémorisation estoptionnelle et il reste
        libre de modifier vos coordonnées ultérieurement).
      </p>

      <p class="fy-section__para">
        L’Utilisateur / le Partenairea toujours la possibilité de désactiver ou d'empêcher le placement de ces cookiesou
        d'être informé de leur création en configurant son navigateur.
      </p>
    </section>

    <!-- Article 18– Désinscription -->
    <section class="fy-section">
      <span class="fy-section__title">Article 18– Désinscription</span>
      <p class="fy-section__para">Les Services sont souscrits pour une durée indéterminée.</p>

      <p class="fy-section__para">
        L’Utilisateur peut se désinscrire des Services à tout moment en adressant une demande par courrier électronique
        à l’adresse contact@proofeo.io
      </p>

      <p class="fy-section__para">
        La désinscription est effective dans un délai maximum de sept jours à compter de cette demande. Elle entraîne la
        suppression automatique du Compte Utilisateur de l’Utilisateur.
      </p>
    </section>

    <!-- Article 19 – Indivisibilité des clauses et Non renonciation -->
    <section class="fy-section">
      <span class="fy-section__title">Article 19 – Indivisibilité des clauses et Non renonciation</span>
      <p class="fy-section__para">
        La nullité d’un article quelconque n'entraîne aucunement la nullité des Conditions Générales.
      </p>

      <p class="fy-section__para">
        L'inapplication temporaire ou permanente d'une ou plusieurs clauses des Conditions Générales par Proofeone
        saurait valoir renonciation de sa part aux autres clauses qui continuent à produire leurs effets.
      </p>
    </section>

    <!-- Article 20 – Réclamation -->
    <section class="fy-section">
      <span class="fy-section__title">Article 20 – Réclamation</span>
      <p class="fy-section__para">
        L’Utilisateur / le partenairepeut adresser par courrier électronique à l’adresse email contact@proofeo.io ou par
        lettre recommandée avec demande d’avis de réception à l’adresse 25 rue de la Libération 92500 Rueil-Malmaison
      </p>
    </section>

    <!-- Article 21 – Langue du Contrat – Litige -->
    <section class="fy-section">
      <span class="fy-section__title">Article 21 – Langue du Contrat – Litige</span>
      <p class="fy-section__para">
        Pour tout litige relatif aux Services Proofeo et aux Conditions Générales, le Tribunal compétent sera le
        Tribunal de commerce de Nanterre s’agissant des partenaires et le Tribunal de grande instance de Paris pour les
        Utilisateurs.
      </p>

      <p class="fy-section__para">
        Les Conditions Générales sont rédigées en langue française et sont soumises à la loi française.
      </p>
    </section>

    <!-- SECTION EXAMPLE -->
    <!-- <section class="fy-section">
      <span class="fy-section__title">ARTICLE 2. INSCRIPTION ET ACCÈS A LA SOLUTION</span>
      <div class="fy-section__sub-title">
        <span>2.1 Modalités de souscription</span>
      </div>

      <p class="fy-section__para">

      </p>

      <p class="fy-section__para">

      </p>

      <p class="fy-section__para">

      </p>

      <p class="fy-section__para">

      </p>
    </section> -->
  </div>
</div>
